import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useTranslation } from "react-i18next";
import { getTwilioVoiceToken } from "../api";
import { Device } from "twilio-client";
import { useNavigate } from "react-router-dom";
import { setCurrentLanguage } from "../redux/reducers/languageReducer";
import DialPad from "../components/DialPad";

export default function PhoneCallTwilio() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const callData = useAppSelector((state) => state.call.currentCall);

  const [callTimerSerialize, setCallTimerSerialize] = useState<string>("00:00");
  const [callState, setCallState] = useState<string>("");

  const [secondTimer, setSecondTimer] = useState<any>(null);
  const [device, setDevice] = useState<any>(null);

  const [extensionInput, setExtensionInput] = useState("");

  const callOnce = useRef<boolean>(false);

  const handleSecondCount = () => {
    const startTime = new Date().getTime();

    const updateTimer = () => {
      const currentTime = new Date().getTime();
      const elapsedTime = Math.floor((currentTime - startTime) / 1000);

      const minutes = String(Math.floor(elapsedTime / 60)).padStart(2, "0");
      const seconds = String(elapsedTime % 60).padStart(2, "0");

      setCallTimerSerialize(`${minutes}:${seconds}`);
    };

    setSecondTimer(setInterval(updateTimer, 1000));
  };

  const handleCall = async (device: any) => {
    let fallbackNumber =
      callData && callData.fallBackCall
        ? callData.fallBackCall
        : localStorage.getItem("fallbacknumber");

    const params = {
      To: fallbackNumber.replace("|", "").split("#")[0],
      Dept: fallbackNumber.split("#")[1] || null,
    };

    setCallState(t("calling"));

    if (device) {
      const outgoingConnection = device.connect(params);

      outgoingConnection.on("ringing", () => {
        if (fallbackNumber.split("#").length > 2 && device.activeConnection()) {
          fallbackNumber.split("#").forEach((number: string, index: number) => {
            if (index > 1) {
              device.activeConnection().sendDigits(number);
            }
          });
        }

        setCallState(t("ringing"));
      });
    }
  };

  useEffect(() => {
    if (callOnce.current === false) {
      callOnce.current = true;

      const asyncFunction = async () => {
        const device = new Device();
        setDevice(device);

        const tokenResponse = await getTwilioVoiceToken();

        device.setup(tokenResponse.token, {
          enableRingingState: true,
        });

        if (device.status() === "ready") {
          handleCall(device);
        }

        device.on("ready", () => {
          handleCall(device);
        });

        device.on("error", (error) => {
          setCallState(error.message);
        });

        device.on("connect", () => {
          setCallState(t("onCall"));
        });

        device.on("disconnect", () => {
          setCallState(t("callEnded"));
          device.disconnectAll();
          device.destroy();

          setTimeout(() => {
            i18n.changeLanguage("en");
            dispatch(setCurrentLanguage("en"));

            localStorage.removeItem("fallbacknumber");
            navigate("/");
            window.location.reload();
          }, 1500);
        });
      };

      navigator.mediaDevices.getUserMedia({ audio: true });
      handleSecondCount();

      asyncFunction();

      return () => {
        clearInterval(secondTimer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDigitPress = (digit: string) => {
    setExtensionInput((prevInput) => prevInput + digit);

    if (device && device.activeConnection()) {
      device.activeConnection().sendDigits(digit);
    }
  };

  if (!callData && !localStorage.getItem("fallbacknumber")) {
    return (
      <div className="w-full min-h-screen flex flex-col justify-center items-center px-5">
        <h1 className="text-3xl md:text-5xl font-bold text-center text-black mb-7 w-full">
          Something went wrong.... Please try again.
        </h1>
      </div>
    );
  }

  return (
    <>
      <div className="w-full min-h-screen flex flex-col justify-center items-center p-5 mt-24 2xl:mt-5 mb-5">
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-7 w-full">
          {t("fallBackStatus")}
        </h1>
        <p className="text-2xl md:text-4xl font-bold text-center mb-7 w-full">
          {callState}
        </p>
        <p className="text-2xl md:text-4xl font-bold text-center mb-7 w-full">
          {callTimerSerialize}
        </p>

        <button
          className="bg-red text-white px-20 py-4 rounded-full mt-12 mb-7"
          onClick={() => {
            if (device) {
              device.disconnectAll();
              device.destroy();
            }
            i18n.changeLanguage("en");
            dispatch(setCurrentLanguage("en"));
            localStorage.removeItem("fallbacknumber");
            navigate("/");
            window.location.reload();
          }}
        >
          {t("endCall")}
        </button>

        <div className="w-full flex justify-center my-4">
          <input
            type="text"
            readOnly
            value={extensionInput}
            className="text-center text-2xl font-semibold w-64 border-b-2 border-gray-300 bg-transparent focus:outline-none"
          />
        </div>
        <DialPad onDigitPress={handleDigitPress} />
      </div>
    </>
  );
}
