import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../../components/LoadingScreen";
import { getStationByDwelling } from "../../../api";
import { setCallData } from "../../../redux/reducers/callReducer";
import { setDwellingData } from "../../../redux/reducers/dwellingReducer";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "../../../hooks";

interface FinalMessageProps {
  buttonId: number;
  answers: string[];
  handleAddVisitorSignIn: () => Promise<void>;
}

export default function FinalMessage({
  buttonId,
  answers,
  handleAddVisitorSignIn,
}: FinalMessageProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();

  const stationData = useAppSelector((state) => state.data.stationData);
  const onlineDepartmentsRedux = useAppSelector(
    (state) => state.data.onlineDepartments
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirectAlert, setRedirectAlert] = useState<boolean>(false);

  const [afterSignInMessage, setAfterSignInMessage] = useState<string>("");

  const sentSignIn = useRef(false);

  useEffect(() => {
    if (!stationData) {
      navigate("/options");
    }
  }, [navigate, stationData]);

  useEffect(() => {
    if (!stationData) return;
    if (sentSignIn.current) return;

    sentSignIn.current = true;

    const asyncFunction = async () => {
      if (buttonId && stationData) {
        const isMultipleAttachments =
          query.get("isMultipleAttachments") === "true";

        const selectedButton = stationData.stationButton.find(
          (a: any) => a.id === buttonId
        );

        let attachmentButton = selectedButton.Attachment;

        if (isMultipleAttachments) {
          const selectedAttachment = attachmentButton.find(
            (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
          );

          if (selectedAttachment) {
            attachmentButton = selectedAttachment;
          }
        }

        if (!attachmentButton) {
          navigate("/options");
          return;
        }

        setAfterSignInMessage(attachmentButton.AfterSignInMessage);

        await handleAddVisitorSignIn();

        setIsLoading(false);

        if (attachmentButton && attachmentButton.IsVideoCall) {
          setRedirectAlert(true);

          setTimeout(async () => {
            const questionForms = JSON.parse(attachmentButton.FieldsJson);

            const hostSet = questionForms.find(
              (a: any) => a.Type === "host" && a.IsHostSet
            );

            const answersJson = questionForms.map(
              (question: any, index: number) => {
                return {
                  Name: question.Name,
                  Type: question.Type,
                  Answer:
                    question.Type === "host" && hostSet
                      ? hostSet.Host
                      : answers[index],
                };
              }
            );

            let receptionist: number = 0;

            if (hostSet) {
              receptionist = parseInt(hostSet.Host);
            } else {
              receptionist = parseInt(
                answersJson.find((a: any) => a.Type === "host")
                  ? answersJson.find((a: any) => a.Type === "host").Answer
                  : 0
              );
            }

            const selectedDepartment = stationData.departments.find(
              (a: any) => a.id === receptionist
            );

            if (
              !!selectedDepartment.FallbackCall &&
              selectedDepartment.IsPhoneOnly
            ) {
              const callData: any = {
                fallBackCall: selectedDepartment.FallbackCall,
              };

              dispatch(setCallData(callData));
              navigate("/call-fallback");
            } else {
              let isDepartmentOnline = false;
              if (
                onlineDepartmentsRedux.length > 0 &&
                onlineDepartmentsRedux.includes(`${receptionist}`)
              ) {
                isDepartmentOnline = true;
              } else if (
                receptionist === 0 &&
                onlineDepartmentsRedux.length > 0 &&
                onlineDepartmentsRedux.includes("0")
              ) {
                isDepartmentOnline = true;
              } else if (selectedDepartment.MicrosoftCallerIds.length > 0) {
                isDepartmentOnline = true;
              }

              if (!isDepartmentOnline) {
                if (!!selectedDepartment.FallbackCall) {
                  const callData: any = {
                    fallBackCall: selectedDepartment.FallbackCall,
                  };

                  dispatch(setCallData(callData));
                  navigate("/call-fallback");
                } else {
                  setTimeout(() => {
                    navigate("/options");
                    window.location.reload();
                  }, 4000);
                }
              } else {
                const dwelling = await getStationByDwelling(
                  stationData.DwellingId
                );
                if (dwelling.status === 1) {
                  const callData: any = {
                    callType: 6,
                    callTypeName: "Visitor Sign In Call",
                    selectedDepartments:
                      receptionist === 0 ? "" : `${receptionist}`,
                    fallBackCall: selectedDepartment
                      ? selectedDepartment?.FallbackCall
                      : "",
                    microsoftCallerIds: selectedDepartment
                      ? selectedDepartment?.MicrosoftCallerIds
                      : [],
                    regularUserIds: selectedDepartment
                      ? selectedDepartment?.RegularUserIds
                      : [],
                    callForwardTime: selectedDepartment.CallForwardTime
                      ? selectedDepartment.CallForwardTime
                      : stationData.CallForwardTime,
                  };

                  dispatch(setCallData(callData));
                  dispatch(setDwellingData(dwelling.data));
                  navigate("/call");
                }
              }
            }
          }, 4000);
        } else {
          setTimeout(() => {
            navigate("/options");
            window.location.reload();
          }, 4000);
        }
      } else {
        navigate("/options");
      }
    };

    asyncFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <Transition appear show={redirectAlert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000001]"
          onClose={() => {
            setRedirectAlert(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-9 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-bold leading-7 text-gray-900 text-center"
                  >
                    {t("visitorSignInRedirect")}
                  </Dialog.Title>
                  <div className="flex justify-center mt-4">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-12 w-12 text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.009 8.009 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="w-full h-full flex flex-col items-center justify-center min-h-[90vh]">
        <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
          <svg
            width="96"
            height="96"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              navigate("/options");
            }}
            className="cursor-pointer relative z-50"
          >
            <path
              d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="relative w-full h-[70vh] flex flex-col justify-center items-center mt-10 z-2">
          {afterSignInMessage ? (
            <div
              dangerouslySetInnerHTML={{
                __html: afterSignInMessage,
              }}
              style={{
                width: "70%",
              }}
            />
          ) : (
            <>
              <h1 className="text-7xl text-black font-bold">
                {t("visitorSignInFinalTitle")}
              </h1>
              <h2 className="text-4xl text-black mt-2">
                {t("visitorSignInFinalSubTitle")}
              </h2>
            </>
          )}
        </div>
      </div>
    </>
  );
}
