/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import VfdLogo from "../assets/images/logo.png";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import AvatarComponent from "../components/AvatarComponent";
import { avatarCoordinates } from "../assets/utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { sendUserQuestion } from "../api";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

const getVoices = () => {
  let voices = speechSynthesis.getVoices();
  if (!voices.length) {
    let utterance = new SpeechSynthesisUtterance("");
    speechSynthesis.speak(utterance);
    voices = speechSynthesis.getVoices();
  }

  return voices;
};

const waitingResponseSound = () => {
  const waitingLines = [
    "Hmmm... let me think about this",
    "Let me think about this",
    "Great question!",
    "I'm thinking",
    "Let me think",
    "Let me think about this",
    "Let me think about that",
    "Let me think about that for a second",
    "Let me think about that for a moment",
    "Let me think about that for a minute",
    "Let me think about that for a while",
    "Let me think about that for a bit",
    "Let me think about that for a little while",
    "Let me think about that for a little bit",
    "Let me think about that for a short while",
    "Let me think about that for a short bit",
    "Let me think about that for a short moment",
    "Let me think about that for a short minute",
    "Let me think about that for a short second",
    "Let me think about that for a short time",
    "Let me think about that for a short period of time",
    "Let me think about that for a short period",
    "Let me think about that for a short duration",
    "Let me think about that for a short spell",
    "Let me think about that for a short stretch",
    "Let me think about that for a short interval",
    "Let me think about that for a short amount of time",
    "Let me think about that for a short while",
    "Let me think about that for a short bit",
    "Let me think about that for a short moment",
    "Let me think about that for a short minute",
    "Let me think about that for a short second",
    "Let me think about that for a short time",
    "Let me think about that for a short period of time",
    "Let me think about that for a short period",
    "Let me think about that for a short duration",
    "Let me think about that for a short spell",
  ];

  const randomIndex = Math.floor(Math.random() * waitingLines.length);

  const speakData = new SpeechSynthesisUtterance();

  speakData.volume = 1; // From 0 to 1
  speakData.rate = 0.75; // From 0.1 to 10
  speakData.pitch = 1; // From 0 to 2
  speakData.text = waitingLines[randomIndex];
  speakData.lang = "en";
  speakData.voice = getVoices()[0];

  speechSynthesis.speak(speakData);
};

export default function Avatar() {
  const isPortrait = window.innerHeight > window.innerWidth;

  const navigate = useNavigate();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const { t, i18n } = useTranslation();
  const { buttonId } = useParams();

  const stationData = useAppSelector((state) => state.data.stationData);
  const dwellingData = useAppSelector((state) => state.dwelling.dwellingData);

  const [avatarId, setAvatarId] = useState<number>(0);
  const [modelUrl, setModelUrl] = useState<string>("");

  const blendshapesRef = useRef<any[]>([]);
  const isOpening = useRef<boolean>(false);
  const talkingInterval = useRef<any>(null);

  useEffect(() => {
    if (buttonId && stationData) {
      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === parseInt(buttonId)
      );

      if (selectedButton) {
        setModelUrl(selectedButton.Attachment.avatar.Url);
        setAvatarId(selectedButton.Attachment.id);
      }
    } else {
      navigate("/options");
    }
  }, [buttonId, navigate, stationData]);

  const startSpeakingAvatar = async (timeToSayText: number) => {
    blendshapesRef.current = avatarCoordinates;

    talkingInterval.current = setInterval(() => {
      blendshapesRef.current.forEach((element) => {
        if (element.categoryName === "jawOpen") {
          if (element.score >= 0.3) isOpening.current = false;
          if (element.score <= 0) isOpening.current = true;

          if (isOpening.current) {
            element.score += 0.01;
          } else {
            element.score -= 0.01;
          }
        }
      });
    }, 25);

    setTimeout(() => {
      clearInterval(talkingInterval.current);

      blendshapesRef.current = avatarCoordinates.map((element) => {
        return {
          ...element,
          score: 0,
        };
      });
    }, timeToSayText);
  };

  const startRecording = () => {
    SpeechRecognition.startListening();
  };

  const stopRecording = async () => {
    SpeechRecognition.stopListening();

    resetTranscript();

    if (transcript.trim().length === 0) return;

    waitingResponseSound();

    const apiResponse = await sendUserQuestion(transcript, avatarId);

    setTimeout(() => {
      if (apiResponse.status === 1) {
        const textToSpeak = apiResponse.data;

        const timeToSayText = textToSpeak.split(" ").length * 0.5 * 1000;

        const speakData = new SpeechSynthesisUtterance();
        speakData.volume = 1; // From 0 to 1
        speakData.rate = 0.75; // From 0.1 to 10
        speakData.pitch = 1; // From 0 to 2
        speakData.text = textToSpeak;
        speakData.lang = i18n.language ?? "en";
        speakData.voice = getVoices()[0];

        startSpeakingAvatar(timeToSayText);

        setTimeout(() => {
          speechSynthesis.speak(speakData);
        }, 1000);
      } else {
        alert(apiResponse.error);
      }
    }, 1500);
  };

  if (!browserSupportsSpeechRecognition || !("speechSynthesis" in window)) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div className="w-full h-full p-2">
      <div className="w-full flex items-center gap-5 relative mt-10">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            navigate("/options");
          }}
          className="cursor-pointer mt-6 absolute"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
        <h1
          className="text-3xl md:text-5xl font-bold text-center mt-7 w-full"
          style={{
            color:
              dwellingData && dwellingData.WelcomeTextColor
                ? dwellingData.WelcomeTextColor
                : "#000000",
          }}
        >
          Ask the AI Avatar
        </h1>
      </div>

      <div className="min-h-[80vh] flex justify-center items-center flex-col gap-8">
        {modelUrl && (
          <div
            className={`w-[60vw] rounded-full overflow-hidden ${
              isPortrait ? "h-[60vw]" : "h-[40vw]"
            }`}
          >
            <Canvas
              style={{ position: "relative" }}
              camera={{ fov: 25, position: [0, -0.1, 1.2] }}
              shadows
            >
              <ambientLight intensity={0.6} />
              <pointLight
                position={[10, 10, 10]}
                color={new THREE.Color(1, 1, 0)}
                intensity={120}
                castShadow
              />
              <pointLight
                position={[-10, 0, 10]}
                color={new THREE.Color(1, 0, 0)}
                intensity={160}
                castShadow
              />
              <pointLight position={[0, 0, 10]} intensity={60} castShadow />
              <AvatarComponent url={modelUrl} blendshapesRef={blendshapesRef} />
            </Canvas>
          </div>
        )}

        <button
          onMouseDown={startRecording}
          onMouseUp={stopRecording}
          onTouchStart={startRecording}
          onTouchEnd={stopRecording}
          className="rounded-full w-36 h-36 bg-primary text-white font-bold text-lg cursor-pointer"
        >
          {listening ? "Release to stop" : "Push to talk"}
        </button>

        <p className="text-xl text-light-gray px-10">
          {transcript ? transcript : ""}
        </p>
      </div>
    </div>
  );
}
