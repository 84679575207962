import React from "react";
import { useFrame, useGraph } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { useRef, useEffect, MutableRefObject } from "react";

interface AvatarProps {
  url: string;
  blendshapesRef: MutableRefObject<any[]>;
}

const Avatar = ({ url, blendshapesRef }: AvatarProps) => {
  const { scene } = useGLTF(url);
  const { nodes } = useGraph(scene);
  const headMeshRef = useRef<any[]>([]);

  useEffect(() => {
    if (nodes.Wolf3D_Head) headMeshRef.current.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMeshRef.current.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMeshRef.current.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMeshRef.current.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom)
      headMeshRef.current.push(nodes.Wolf3D_Head_Custom);
  }, [nodes]);

  useFrame(() => {
    if (blendshapesRef.current.length > 0) {
      blendshapesRef.current.forEach((element) => {
        headMeshRef.current.forEach((mesh) => {
          const index = mesh.morphTargetDictionary[element.categoryName];
          if (index !== undefined && index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });
    }
  });
  // eslint-disable-next-line
  return <primitive object={scene} position={[0, -1.71, 0]} />;
};

export default Avatar;
