import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import InputWithLabel from "./InputWithLabel";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import { Page, Document, pdfjs } from "react-pdf";
import { getDocumentBufferData } from "../api";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Terms({
  documentPath,
  guestName,
  guestEmail,
  isAddSignature,
  handleSignSubmit,
}: {
  documentPath: string;
  guestName: string;
  guestEmail: string;
  isAddSignature: boolean;
  handleSignSubmit: (
    data: any,
    imgData: string,
    hasSignature: boolean
  ) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [fullName, setFullName] = useState<string>(guestName ? guestName : "");
  const [email, setEmail] = useState<string>(guestEmail ? guestEmail : "");

  const [canvasWidth, setCanvasWidth] = useState<number>(600);
  const [canvasHeight, setCanvasHeight] = useState<number>(200);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(true);

  const signature = useRef(null);
  const container = useRef<HTMLDivElement | null>(null);
  const [documentBufferData, setDocumentBufferData] = useState<any>(null);

  const file = useMemo(
    () => ({ data: documentBufferData }),
    [documentBufferData]
  );

  useEffect(() => {
    if (container.current) {
      setCanvasWidth(container.current.offsetWidth);
      setCanvasHeight(container.current.offsetHeight);
    }
  }, [container]);

  useEffect(() => {
    const fetchDocument = async (filePath: string) => {
      const requestData = await getDocumentBufferData(filePath);

      setDocumentBufferData(requestData.data.data);
    };

    fetchDocument(documentPath);
  }, []);

  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1000001]"
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[80%] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="relative h-full w-full flex flex-col justify-center items-center">
                  {documentBufferData ? (
                    <Document
                      file={file}
                      onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);
                      }}
                    >
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        height={
                          window.innerHeight > 1800
                            ? window.innerHeight - 700
                            : window.innerHeight - 300
                        }
                      />
                    </Document>
                  ) : (
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                  )}

                  {numPages > 1 && pageNumber > 1 && (
                    <button
                      className="absolute left-[1rem] top-1/2 z-[100001] bg-gray-900 text-white px-5 py-2 rounded-md"
                      onClick={() => {
                        setPageNumber(pageNumber - 1);
                      }}
                      title="Previous Page"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="w-6 h-6 cursor-pointer"
                      />
                    </button>
                  )}

                  {numPages > 1 && pageNumber < numPages && (
                    <button
                      className="absolute right-[1rem] top-1/2 z-[100001] bg-gray-900 text-white px-5 py-2 rounded-md"
                      onClick={() => {
                        setPageNumber(pageNumber + 1);
                      }}
                      title="Next Page"
                    >
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="w-6 h-6 cursor-pointer"
                      />
                    </button>
                  )}
                </div>
                {isAddSignature && (
                  <>
                    <div className="w-full flex justify-center mt-16 px-24">
                      <InputWithLabel
                        type="text"
                        value={fullName}
                        setValue={setFullName}
                        placeholder={t("yourName")}
                        extraClasses="w-full md:w-3/4"
                        placeholderSize="text-2xl"
                      />
                    </div>

                    <div className="w-full flex justify-center mt-12 px-24">
                      <InputWithLabel
                        type="email"
                        value={email}
                        setValue={setEmail}
                        placeholder={t("yourEmail")}
                        extraClasses="w-full md:w-3/4"
                        placeholderSize="text-2xl"
                      />
                    </div>
                    <div className="w-full flex justify-center mt-8 px-24">
                      <div
                        className="w-full md:w-3/4 flex flex-col items-start"
                        ref={container}
                      >
                        <p className="text-light-gray text-xl">Signature</p>
                        <SignatureCanvas
                          ref={signature}
                          penColor="black"
                          canvasProps={{
                            width: canvasWidth,
                            height: canvasHeight,
                            className:
                              "border-2 border-light-gray rounded-lg mt-2 h-[8rem] w-full",
                          }}
                          backgroundColor="#ffffff"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="w-full flex justify-center px-5">
                  <button
                    className={`mt-7 flex items-center justify-center border-2 rounded-full py-5 text-3xl text-white ${
                      isAddSignature && (fullName === "" || email === "")
                        ? "border-light-gray bg-light-gray"
                        : "border-primary bg-primary hover:bg-white hover:text-primary"
                    } w-full px-5 md:w-2/3`}
                    disabled={
                      isAddSignature && (fullName === "" || email === "")
                    }
                    onClick={async () => {
                      setShowModal(false);
                      await handleSignSubmit(
                        {
                          name: fullName,
                          email: email,
                        },
                        signature.current
                          ? (signature.current as any)
                              .getTrimmedCanvas()
                              .toDataURL("image/png")
                          : "",
                        isAddSignature
                      );
                    }}
                  >
                    {isAddSignature && (fullName === "" || email === "")
                      ? t("termsfillall")
                      : t("termsAccept")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
