import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getDepNamesById,
  getStationByDwelling,
  sendFCMNotification,
  sendSMS,
} from "../api";
import { useTranslation } from "react-i18next";
import { setCallData } from "../redux/reducers/callReducer";
import { setDwellingData } from "../redux/reducers/dwellingReducer";
import { Dialog, Transition } from "@headlessui/react";
import InputWithLabel from "../components/InputWithLabel";

const sortByLocalLanguage = (a: any, b: any, language: string) => {
  if (a[`CallType${language}`] < b[`CallType${language}`]) {
    return -1;
  }
  if (a[`CallType${language}`] > b[`CallType${language}`]) {
    return 1;
  }
  return 0;
};

export default function DepOption() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { buttonId } = useParams();

  const onlineDepartmentsRedux = useAppSelector(
    (state) => state.data.onlineDepartments
  );
  const departmentsPresencesRedux = useAppSelector(
    (state) => state.data.departmentPresences
  );
  const stationData = useAppSelector((state) => state.data.stationData);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");

  const [departmentsPresences, setDepartmentsPresences] = useState<any[]>([]);
  const [originalDepartmentsPresences, setOriginalDepartmentsPresences] =
    useState<any[]>([]);
  const [callTypeDisable, setCallTypeDisable] = useState<any>(null);
  const [callTypeDisableTimeout, setCallTypeDisableTimeout] =
    useState<any>(null);

  useEffect(() => {
    if (
      stationData &&
      stationData.backgroundImage &&
      stationData.backgroundImage.Url
    ) {
      document.body.style.backgroundImage = `url(${stationData.backgroundImage.Url})`;
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundSize = "cover";
      document.body.style.minHeight = "100vh";
    }

    return () => {
      document.body.style.backgroundImage = "none";
    };
  }, [stationData]);

  useEffect(() => {
    const asyncFunction = async () => {
      if (!departmentsPresencesRedux) {
        navigate("/options");
        return;
      }

      const filteredButton = departmentsPresencesRedux.filter(
        (department: any) => department.id === parseInt(buttonId!)
      )[0];

      if (filteredButton) {
        const departments = filteredButton.SelectedDepartments.split("|");

        setIsLoading(true);
        const depNamesResponse = await getDepNamesById(
          filteredButton.SelectedDepartments
        );
        setIsLoading(false);

        const reqNames = depNamesResponse.names;
        const reqFallBackCalls = depNamesResponse.fallbackCalls;
        const microsoftCallerIds = depNamesResponse.microsoftCallerIds;
        const regularUserIds = depNamesResponse.regularUserIds;

        departments.forEach((department: any, index: number) => {
          const filtered = reqNames.filter(
            (reqName: any) => reqName.id === parseInt(department)
          );

          if (filtered.length === 0) {
            departments.splice(index, 1);
          }
        });

        const newDepartments = departments.map((department: any) => {
          const fallBack = reqFallBackCalls
            .map((reqFallBackCall: any) => {
              if (reqFallBackCall.id === parseInt(department)) {
                return reqFallBackCall.fallbackCall;
              }

              return undefined;
            })
            .filter((reqFallBackCall: any) => reqFallBackCall !== undefined)[0];

          const currentMicrosoftCallerIds = microsoftCallerIds.filter(
            (microsoftCallerId: any) =>
              microsoftCallerId.id === parseInt(department)
          )[0];

          const currentRegularCallerIds = regularUserIds.filter(
            (regularUserId: any) => regularUserId.id === parseInt(department)
          )[0];

          return {
            ...filteredButton,
            CallTypeEN: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeFR: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeDE: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeAR: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeES: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeFI: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeHU: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeIT: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeJA: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeKO: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeRO: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeRU: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeSV: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeTR: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeUK: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypePL: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeNO: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            CallTypeFIL: reqNames
              .map((reqName: any) => {
                if (reqName.id === parseInt(department)) {
                  return reqName.name;
                }

                return undefined;
              })
              .filter((reqName: any) => reqName !== undefined)[0],
            FallBackCall: fallBack,
            SelectedDepartments: department,
            isOnline:
              currentMicrosoftCallerIds &&
              currentMicrosoftCallerIds.callerIds.length > 0
                ? true
                : onlineDepartmentsRedux.includes(department),
            MicrosoftCallerIds:
              currentMicrosoftCallerIds && currentMicrosoftCallerIds.callerIds
                ? currentMicrosoftCallerIds.callerIds
                : [],
            RegularUserIds:
              currentRegularCallerIds && currentRegularCallerIds.callerIds
                ? currentRegularCallerIds.callerIds
                : [],
          };
        });

        setOriginalDepartmentsPresences(newDepartments);
        setDepartmentsPresences(newDepartments);
      }
    };
    asyncFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (i18n.language === "fr") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeFR &&
            button.CallTypeFR.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "de") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeDE &&
            button.CallTypeDE.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "ar") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeAR &&
            button.CallTypeAR.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "es") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeES &&
            button.CallTypeES.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "fi") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeFI &&
            button.CallTypeFI.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "hu") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeHU &&
            button.CallTypeHU.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "it") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeIT &&
            button.CallTypeIT.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "ja") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeJA &&
            button.CallTypeJA.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "ko") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeKO &&
            button.CallTypeKO.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "ro") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeRO &&
            button.CallTypeRO.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "ru") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeRU &&
            button.CallTypeRU.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "sv") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeSV &&
            button.CallTypeSV.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "tr") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeTR &&
            button.CallTypeTR.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "uk") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeUK &&
            button.CallTypeUK.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "nb") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeNO &&
            button.CallTypeNO.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "pl") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypePL &&
            button.CallTypePL.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else if (i18n.language === "fil") {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeFIL &&
            button.CallTypeFIL.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    } else {
      if (search.length > 0) {
        const filteredButtons = departmentsPresences.filter(
          (button) =>
            button.CallTypeEN &&
            button.CallTypeEN.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentsPresences(filteredButtons);
      } else {
        setDepartmentsPresences(originalDepartmentsPresences);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleButtonClick = async (departmentPresence: any) => {
    if (
      departmentPresence.isOnline ||
      (departmentPresence.FallBackCall &&
        departmentPresence.FallBackCall.length > 0)
    ) {
      const selectedDepartments = departmentPresence.SelectedDepartments;

      let callTypeName = departmentPresence[
        "CallType" + i18n.language.toUpperCase()
      ]
        ? departmentPresence["CallType" + i18n.language.toUpperCase()]
        : null;

      if (callTypeName === null) {
        callTypeName = t("calltype");
      }

      if (departmentPresence.isOnline) {
        if (
          selectedDepartments &&
          selectedDepartments.length > 1 &&
          selectedDepartments.split("|").length > 1
        ) {
          navigate(`/depoption/${departmentPresence.id}`);
        } else {
          let callData: any;

          callData = {
            callType: departmentPresence.id,
            callTypeName: callTypeName,
            selectedDepartments: selectedDepartments,
            fallBackCall: departmentPresence.FallBackCall,
            microsoftCallerIds: departmentPresence.MicrosoftCallerIds,
            regularUserIds: departmentPresence.RegularUserIds,
          };
          if (
            departmentPresence.fcmTokens &&
            departmentPresence.fcmTokens.length > 0
          ) {
            sendFCMNotification(
              departmentPresence.fcmTokens,
              `${t("fcmBody")} ${stationData.StationName} - ${
                callData.callTypeName
              }`,
              `${t("fcmTitle")}`
            );
          }
          if (
            departmentPresence.smsArr &&
            departmentPresence.smsArr.length > 0
          ) {
            sendSMS(
              departmentPresence.smsArr,
              `${t("fcmBody")} ${stationData.StationName} - ${
                callData.callTypeName
              }`
            );
          }

          const dwelling = await getStationByDwelling(stationData.DwellingId);
          if (dwelling.status === 1) {
            dispatch(setCallData(callData));
            dispatch(setDwellingData(dwelling.data));
            navigate("/call");
          }
        }
      } else {
        if (
          (selectedDepartments &&
            selectedDepartments.length > 1 &&
            selectedDepartments.split("|").length > 1) ||
          departmentPresence.FallBackCall === "12345678910111213141516"
        ) {
          navigate(`/depoption/${departmentPresence.id}`);
        } else {
          let callData: any;
          if (!!departmentPresence.FallBackCall) {
            callData = {
              fallBackCall: departmentPresence.FallBackCall,
            };

            dispatch(setCallData(callData));
            navigate("/call-fallback");
          } else {
            callData = {
              callType: departmentPresence.id,
              callTypeName: callTypeName,
              selectedDepartments: selectedDepartments,
              fallBackCall: departmentPresence.FallBackCall,
              microsoftCallerIds: departmentPresence.MicrosoftCallerIds,
              regularUserIds: departmentPresence.RegularUserIds,
            };
            if (
              departmentPresence.fcmTokens &&
              departmentPresence.fcmTokens.length > 0
            ) {
              sendFCMNotification(
                departmentPresence.fcmTokens,
                `${t("fcmBody")} ${stationData.StationName} - ${
                  callData.callTypeName
                }`,
                `${t("fcmTitle")}`
              );
            }
            if (
              departmentPresence.smsArr &&
              departmentPresence.smsArr.length > 0
            ) {
              sendSMS(
                departmentPresence.smsArr,
                `${t("fcmBody")} ${stationData.StationName} - ${
                  callData.callTypeName
                }`
              );
            }

            const dwelling = await getStationByDwelling(stationData.DwellingId);

            if (dwelling.status === 1) {
              dispatch(setCallData(callData));
              dispatch(setDwellingData(dwelling.data));
              navigate("/call");
            }
          }
        }
      }
    } else {
      setCallTypeDisable(t("departmentAlertDetails"));

      setCallTypeDisableTimeout(
        setTimeout(() => {
          setCallTypeDisable(null);
        }, 5000)
      );
    }
  };

  return (
    <>
      <Transition appear show={!!callTypeDisable} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setCallTypeDisable(null);

            if (callTypeDisableTimeout) {
              clearTimeout(callTypeDisableTimeout);
            }
            setCallTypeDisableTimeout(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="absolute top-0 right-0 mt-4 mr-4">
                    <button
                      type="button"
                      className="bg-white rounded-full flex items-center justify-center text-gray-400 focus:outline-none"
                      onClick={() => {
                        setCallTypeDisable(null);

                        if (callTypeDisableTimeout) {
                          clearTimeout(callTypeDisableTimeout);
                        }
                        setCallTypeDisableTimeout(null);
                      }}
                    >
                      <span className="sr-only">Close</span>

                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900 text-center"
                  >
                    {t("departmentAlertTitle")}
                  </Dialog.Title>
                  <div className="mt-6 w-full flex justify-center">
                    <p className="text-light-gray">{callTypeDisable}</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            navigate("/options");
          }}
          className="cursor-pointer relative z-50"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
      </div>

      <div className="w-full flex justify-center mt-10">
        {stationData && stationData.logoImage && stationData.logoImage.Url ? (
          <img
            src={stationData.logoImage.Url}
            alt="logo"
            style={{ width: stationData.LogoSize ?? 150 }}
          />
        ) : null}
      </div>

      <div className="relative w-full min-h-[75vh] flex flex-col justify-center pb-5 z-2">
        <div className="h-full w-full flex flex-col justify-center items-center px-5 mt-16">
          {stationData &&
            stationData.ShowSearchBar &&
            originalDepartmentsPresences.length > 5 && (
              <InputWithLabel
                type="text"
                value={search}
                setValue={setSearch}
                placeholder={t("search")}
                extraClasses="mt-7 w-80"
                placeholderSize="text-2xl"
                isGreen={false}
                hasSeparatePlaceholder
              />
            )}

          <div className="container mx-auto w-full lg:w-[95%] mb-24">
            <div
              className={`grid gap-5 lg:gap-7 mt-12 ${
                departmentsPresences.length === 1
                  ? "grid-cols-1"
                  : `${stationData ? (stationData.StationColNumber === 1 ? "landscape:grid-cols-1 portrait:grid-cols-1" : stationData.StationColNumber === 2 ? "landscape:grid-cols-2 portrait:grid-cols-2" : stationData.StationColNumber === 3 ? "landscape:grid-cols-3 portrait:grid-cols-2" : stationData.StationColNumber === 4 ? "landscape:grid-cols-4 portrait:grid-cols-2" : stationData.StationColNumber === 5 ? "landscape:grid-cols-5 portrait:grid-cols-2" : "landscape:grid-cols-6 portrait:grid-cols-2") : "portrait:grid-cols-1 landscape:grid-cols-2"}`
              }`}
            >
              {departmentsPresences
                .sort((a, b) =>
                  sortByLocalLanguage(a, b, i18n.language.toLocaleUpperCase())
                )
                .map((departmentPresence, index) => {
                  const isDisabled =
                    !departmentPresence.isOnline &&
                    (departmentPresence.FallBackCall === null ||
                      departmentPresence.FallBackCall === undefined ||
                      departmentPresence.FallBackCall.trim().length === 0);

                  if (departmentPresence.Enable) {
                    return (
                      <div
                        className="w-full flex justify-center items-center"
                        key={index}
                      >
                        <button
                          key={index}
                          className={`flex items-center justify-center rounded-full text-xl md:text-3xl font-bold text-white ${
                            isDisabled
                              ? "border-2"
                              : "text-white border-2 hover:saturate-150 hover:brightness-125"
                          } py-7 w-[75vw]`}
                          onClick={() => handleButtonClick(departmentPresence)}
                          style={{
                            backgroundColor: isDisabled
                              ? "#d6d6d6"
                              : departmentPresence.Color
                                ? `${departmentPresence.Color}`
                                : "#99e999",
                            borderColor: isDisabled
                              ? "#d6d6d6"
                              : departmentPresence.Color
                                ? `${departmentPresence.Color}`
                                : "#99e999",
                            color: isDisabled
                              ? "#ffffff"
                              : departmentPresence.TextColor
                                ? `${departmentPresence.TextColor}`
                                : "#ffffff",
                          }}
                        >
                          {departmentPresence[
                            "CallType" + i18n.language.toUpperCase()
                          ]
                            ? i18n.language === "en"
                              ? departmentPresence[
                                  "CallType" + i18n.language.toLocaleUpperCase()
                                ]
                              : departmentPresence[
                                  "CallType" + i18n.language.toLocaleUpperCase()
                                ].split(".")[0]
                            : t("calltype")}
                        </button>
                      </div>
                    );
                  }

                  return null;
                })}
            </div>
          </div>

          {search.length > 0 && departmentsPresences.length === 0 && (
            <div className="w-full flex justify-center items-center">
              <p
                className="text-4xl text-light-gray"
                style={{
                  color:
                    stationData && stationData.WelcomeTextColor
                      ? stationData.WelcomeTextColor
                      : "#a2a2a2",
                }}
              >
                {t("result")}
              </p>
            </div>
          )}

          {isLoading ? (
            <div className="w-full flex justify-center items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-textDark"></div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
