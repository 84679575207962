import { configureStore } from "@reduxjs/toolkit";
import stationDataReducer from "./reducers/stationDataReducer";
import callReducer from "./reducers/callReducer";
import dwellingReducer from "./reducers/dwellingReducer";
import languageReducer from "./reducers/languageReducer";

export const store = configureStore({
  reducer: {
    data: stationDataReducer,
    call: callReducer,
    dwelling: dwellingReducer,
    language: languageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
