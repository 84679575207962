import React, { Fragment, useEffect, useState } from "react";
import VfdLogo from "../assets/images/logo.png";
import InputWithLabel from "../components/InputWithLabel";
import { useAppSelector } from "../redux/hooks";
import { getStationData, linkStation } from "../api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";

export default function Activate() {
  const hasActiveCode = !!localStorage.getItem("stationCode");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [isLoadingStationData, setIsLoadingStationData] =
    useState<boolean>(false);

  const [code, setCode] = useState<string>("");

  useEffect(() => {
    if (stationData) {
      navigate("/options");
    }
  }, [stationData, navigate]);

  useEffect(() => {
    if (hasActiveCode) {
      const interval = setInterval(() => {
        window.location.reload();
      }, 90000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleActivate = async () => {
    if (code.trim().length === 0) {
      alert("Please enter a code");
      return;
    }

    setIsLoadingStationData(true);

    const serverResponse = await linkStation(code);

    if (serverResponse.status === 1) {
      localStorage.setItem("stationCode", code);

      await getStationData(code);

      setIsLoadingStationData(false);
      window.location.reload();
      navigate("/options");
    } else {
      setIsLoadingStationData(false);
      alert(serverResponse.message);
    }
  };

  return (
    <>
      <Transition
        appear
        show={hasActiveCode || isLoadingStationData}
        as={Fragment}
      >
        <Dialog as="div" className="relative z-[1000001]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-9 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-bold leading-6 text-gray-900 text-center"
                  >
                    Your station data is loading
                  </Dialog.Title>

                  <Dialog.Description className="text-lg text-gray-700 text-center mt-4">
                    This should only take a few seconds...
                  </Dialog.Description>

                  <div className="flex justify-center mt-4">
                    <svg
                      className="animate-spin h-10 w-10 text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.009 8.009 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                  <Dialog.Description className="text-lg text-gray-700 text-center mt-10 mb-4">
                    OR
                  </Dialog.Description>
                  <Dialog.Description
                    className="text-lg font-bold underline text-gray-700 text-center cursor-pointer"
                    onClick={() => window.location.reload()}
                  >
                    Refresh station manually
                  </Dialog.Description>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="w-full h-full p-2">
        <div className="w-full flex justify-center mt-4">
          <img src={VfdLogo} alt="logo" className="w-36" />
        </div>

        <div className="min-h-[80vh] flex flex-col items-center justify-center">
          <h1 className="text-black text-3xl lg:text-6xl text-center mt-4 md:mt-12 font-semibold">
            {t("connectTitle")}
          </h1>
          <div className="w-full flex justify-center mt-2">
            <p className="text-light-gray text-xl text-center mt-5 w-11/12 lg:w-1/2">
              {t("connectDetails")}
            </p>
          </div>

          <div className="w-full flex justify-center mt-2">
            <InputWithLabel
              type="text"
              value={code}
              setValue={setCode}
              placeholder={t("connectCode")}
              extraClasses="w-11/12 lg:w-1/2 mt-12"
              placeholderSize="text-4xl"
              textClasses="text-3xl text-light-gray"
            />
          </div>

          <div className="w-full flex justify-center mt-16 mb-24">
            <button
              className="flex items-center justify-center border-primary border-2 rounded-full w-11/12 lg:w-2/5 py-5 text-3xl text-white bg-primary hover:bg-white hover:text-primary"
              onClick={handleActivate}
            >
              {t("connectButton")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
