import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

if (localStorage.getItem("accessToken")) {
  axiosInstance.defaults.headers.common["Authorization"] =
    `Bearer ${localStorage.getItem("accessToken")}`;
}

export default axiosInstance;
