import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Page, Document, pdfjs } from "react-pdf";
import { getDocumentBufferData } from "../api";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "../hooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function DocumentPage() {
  const navigate = useNavigate();
  const { buttonId } = useParams();
  const query = useQuery();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);

  const [documentBufferData, setDocumentBufferData] = useState<any>(null);

  const timerRedirect = useRef<any>(null);

  const file = useMemo(
    () => ({ data: documentBufferData }),
    [documentBufferData]
  );

  useEffect(() => {
    const fetchDocument = async (filePath: string) => {
      const requestData = await getDocumentBufferData(filePath);

      return requestData;
    };

    if (buttonId && stationData) {
      const isMultipleAttachments =
        query.get("isMultipleAttachments") === "true";

      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === parseInt(buttonId)
      );

      if (!selectedButton) {
        navigate("/options");
      }

      let filePath = "";
      if (isMultipleAttachments) {
        const selectedAttachment = selectedButton.Attachment.find(
          (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
        );

        if (selectedAttachment) {
          filePath = selectedAttachment.FilePath;
        }
      } else {
        filePath = selectedButton.Attachment.FilePath;
      }

      if (filePath) {
        fetchDocument(filePath)
          .then((data) => {
            if (data) {
              setDocumentBufferData(data.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/options");
    }
  }, [buttonId, navigate, stationData]);

  useEffect(() => {
    timerRedirect.current = setTimeout(() => {
      navigate("/options");
    }, 60000);

    return () => {
      clearTimeout(timerRedirect.current);
    };
  }, []);

  const resetTimer = () => {
    clearTimeout(timerRedirect.current);

    timerRedirect.current = setTimeout(() => {
      navigate("/options");
    }, 60000);
  };

  return (
    <>
      <div className="w-full pb-5">
        <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
          <svg
            width="96"
            height="96"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              navigate("/options");
            }}
            className="cursor-pointer relative z-50"
          >
            <path
              d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="relative min-h-[90vh] w-full flex flex-col justify-center items-center z-40 mt-20">
          {documentBufferData ? (
            <Document
              file={file}
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
              }}
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                height={
                  window.innerHeight > 1800
                    ? window.innerHeight - 700
                    : window.innerHeight - 300
                }
              />
            </Document>
          ) : (
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          )}

          {numPages > 1 && pageNumber > 1 && (
            <button
              className="absolute left-[1rem] top-1/2 z-[100001] bg-gray-900 text-white px-5 py-2 rounded-md"
              onClick={() => {
                setPageNumber(pageNumber - 1);
                resetTimer();
              }}
              title="Previous Page"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="w-6 h-6 cursor-pointer"
              />
            </button>
          )}

          {numPages > 1 && pageNumber < numPages && (
            <button
              className="absolute right-[1rem] top-1/2 z-[100001] bg-gray-900 text-white px-5 py-2 rounded-md"
              onClick={() => {
                setPageNumber(pageNumber + 1);
                resetTimer();
              }}
              title="Next Page"
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="w-6 h-6 cursor-pointer"
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
