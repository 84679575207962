import React from "react";

export default function LoadingScreen() {
  return (
    <div className="absolute top-0 flex items-center justify-center h-screen w-full bg-opacity-50 bg-gray-500 z-[1000]">
      <div className="flex flex-col justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-textDark"></div>
      </div>
    </div>
  );
}
