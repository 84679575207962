import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnTranslation from "../translations/en/en.json";
import FrTranslation from "../translations/fr/en.json";
import ArTranslation from "../translations/ar/en.json";
import DeTranslation from "../translations/de/en.json";
import EsTranslation from "../translations/es/en.json";
import FiTranslation from "../translations/fi/en.json";
import ItTranslation from "../translations/it/en.json";
import HuTranslation from "../translations/hu/en.json";
import JaTranslation from "../translations/ja/en.json";
import KoTranslation from "../translations/ko/en.json";
import RoTranslation from "../translations/ro/en.json";
import RuTranslation from "../translations/ru/en.json";
import TrTranslation from "../translations/tr/en.json";
import SvTranslation from "../translations/sv/en.json";
import UkTranslation from "../translations/uk/en.json";
import PlTranslation from "../translations/pl/en.json";
import NbTranslation from "../translations/nb/en.json";
import FilTranslation from "../translations/fil/en.json";
import ZhTranslation from "../translations/zh/en.json";
import NlTranslation from "../translations/nl/en.json";

const resources = {
  en: {
    translation: EnTranslation,
  },
  fr: {
    translation: FrTranslation,
  },
  ar: {
    translation: ArTranslation,
  },
  de: {
    translation: DeTranslation,
  },
  es: {
    translation: EsTranslation,
  },
  fi: {
    translation: FiTranslation,
  },
  it: {
    translation: ItTranslation,
  },
  hu: {
    translation: HuTranslation,
  },
  ja: {
    translation: JaTranslation,
  },
  ko: {
    translation: KoTranslation,
  },
  ro: {
    translation: RoTranslation,
  },
  ru: {
    translation: RuTranslation,
  },
  tr: {
    translation: TrTranslation,
  },
  sv: {
    translation: SvTranslation,
  },
  uk: {
    translation: UkTranslation,
  },
  pl: {
    translation: PlTranslation,
  },
  no: {
    translation: NbTranslation,
  },
  fil: {
    translation: FilTranslation,
  },
  zh: {
    translation: ZhTranslation,
  },
  nl: {
    translation: NlTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
