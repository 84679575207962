import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DwellingState {
  dwellingData: any;
}

const initialState: DwellingState = {
  dwellingData: null,
};

export const dwellingSlice = createSlice({
  name: "dwellingSlice",
  initialState,
  reducers: {
    setDwellingData: (state, action: PayloadAction<DwellingState>) => {
      state.dwellingData = action.payload;
    },
  },
});

export const { setDwellingData } = dwellingSlice.actions;
export default dwellingSlice.reducer;
