import React, { useEffect, useState } from "react";
import UnselectedStarImage from "../assets/images/star-unselect.png";
import SelectedStarImage from "../assets/images/star-selected.png";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { editStay } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { setCurrentLanguage } from "../redux/reducers/languageReducer";

export default function Stars() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [stars, setStars] = useState<number>(0);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (
        !localStorage.getItem("callBackLink") &&
        location.pathname.includes("evaluation")
      ) {
        i18n.changeLanguage("en");
        dispatch(setCurrentLanguage("en"));

        navigate("/");
        window.location.reload();
      }
    }, 5000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [dispatch, i18n, location.pathname, navigate]);

  const handleSelection = async (stars: number) => {
    const stayId = localStorage.getItem("stayId");
    if (!stationData || !stayId) {
      navigate("/");
      return;
    }

    const body = {
      id: stayId,
      ownerId: stationData.id,
      evaluation: stars,
    };

    await editStay(body);

    localStorage.removeItem("stayId");
    i18n.changeLanguage("en");
    dispatch(setCurrentLanguage("en"));
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="w-full h-[100vh]">
      <div className="relative h-full w-full flex flex-col justify-center items-center px-5 z-40">
        <h1 className="text-3xl md:text-6xl font-bold text-center mb-7 w-full lg:w-1/2">
          {t("evaluationTitle")}
        </h1>
        <p className="text-lg md:text-xl text-center  w-full lg:w-1/2">
          {t("evaluationQuestion")}
        </p>
        <div className="w-full flex items-center justify-center gap-6 mt-12">
          <img
            src={stars >= 1 ? SelectedStarImage : UnselectedStarImage}
            alt="star"
            className="w-12 md:w-28 cursor-pointer"
            onClick={async () => {
              setStars(1);
              await handleSelection(1);
            }}
          />
          <img
            src={stars > 1 ? SelectedStarImage : UnselectedStarImage}
            alt="star"
            className="w-12 md:w-28 cursor-pointer"
            onClick={async () => {
              setStars(2);
              await handleSelection(2);
            }}
          />
          <img
            src={stars > 2 ? SelectedStarImage : UnselectedStarImage}
            alt="star"
            className="w-12 md:w-28 cursor-pointer"
            onClick={async () => {
              setStars(3);
              await handleSelection(3);
            }}
          />
          <img
            src={stars > 3 ? SelectedStarImage : UnselectedStarImage}
            alt="star"
            className="w-12 md:w-28 cursor-pointer"
            onClick={async () => {
              setStars(4);
              await handleSelection(4);
            }}
          />
          <img
            src={stars > 4 ? SelectedStarImage : UnselectedStarImage}
            alt="star"
            className="w-12 md:w-28 cursor-pointer"
            onClick={async () => {
              setStars(5);
              await handleSelection(5);
            }}
          />
        </div>
      </div>
    </div>
  );
}
