import React from "react";
import VfdLogo from "../assets/images/logo.png";
import { useAppSelector } from "../redux/hooks";

export default function Footer() {
  const hasStationCode = localStorage.getItem("stationCode");

  const stationData = useAppSelector((state) => state.data.stationData);

  return (
    <div className="absolute flex justify-start top-0 left-5 mt-3 z-[100]">
      {hasStationCode &&
      (!stationData || (stationData && !stationData.RemoveVfdLogo)) ? (
        <div className="flex flex-col gap-1 bg-white px-4 py-2 rounded-full">
          <img src={VfdLogo} alt="logo" className="h-6" />
        </div>
      ) : null}
    </div>
  );
}
