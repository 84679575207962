import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DocumentSign from "./Pages/DocumentSign";
import SelfieTake from "./Pages/SelfieTake";
import FinalMessage from "./Pages/FinalMessage";
import InputForms from "./Pages/InputForms";
import { addVisitorSignInData } from "../../api";
import { useAppSelector } from "../../redux/hooks";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../hooks";
import axios from "axios";

export default function VisitorSignIn() {
  const { buttonId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [noInteractivity, setNoInteractivity] = useState<boolean>(false);

  const [noInteractivityTimer, setNoInteractivityTimer] = useState<any>(null);
  const [noInteractivityTime, setNoInteractivityTime] = useState<number>(0);

  const [answers, setAnswers] = useState<string[]>([]);
  const [signatureImageData, setSignatureImageData] = useState<string>("");
  const [selfiePictureImageData, setSelfiePictureImageData] =
    useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(0);

  const resetTimeout = () => {
    clearTimeout(noInteractivityTimer);

    setNoInteractivityTimer(
      setTimeout(() => {
        setNoInteractivity(true);
      }, 60000)
    );

    setNoInteractivityTime(0);
  };

  useEffect(() => {
    resetTimeout();
  }, []);

  useEffect(() => {
    const handleUserInteraction = () => {
      setNoInteractivity(false);
      resetTimeout();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      clearTimeout(noInteractivityTimer);
    };
  }, [noInteractivityTimer]);

  useEffect(() => {
    if (noInteractivity) {
      const countdown = 25;
      let timer = countdown;
      const interval = setInterval(() => {
        setNoInteractivityTime(timer);
        timer -= 1;

        if (timer === 0) {
          clearInterval(interval);
          setNoInteractivity(false);

          navigate("/options");
          window.location.reload();
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setNoInteractivityTime(0);
    }
  }, [noInteractivity]);

  if (!buttonId) {
    return null;
  }

  const handleAddVisitorSignIn = async () => {
    const isMultipleAttachments = query.get("isMultipleAttachments") === "true";
    const stationId = stationData.id;

    const selectedButton = stationData.stationButton.find(
      (a: any) => a.id === parseInt(buttonId)
    );

    let attachmentButton = selectedButton.Attachment;

    if (isMultipleAttachments) {
      const selectedAttachment = attachmentButton.find(
        (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
      );

      if (selectedAttachment) {
        attachmentButton = selectedAttachment;
      }
    }

    if (selectedButton) {
      const questionForms = JSON.parse(attachmentButton.FieldsJson);

      const hostSet = questionForms.find(
        (a: any) => a.Type === "host" && a.IsHostSet
      );

      const answersJson = questionForms.map((question: any, index: number) => {
        return {
          Name: question.Name,
          Type: question.Type,
          Answer:
            question.Type === "host" && hostSet ? hostSet.Host : answers[index],
        };
      });

      const requestVisitorSignin = await addVisitorSignInData(
        stationId,
        isMultipleAttachments
          ? parseInt(query.get("attachmentId") ?? "0")
          : parseInt(selectedButton.SelectedSignInFlow),
        JSON.stringify(answersJson),
        {
          imageData: signatureImageData.split("base64,")[1],
          imageType: "png",
        },
        {
          imageData: selfiePictureImageData.split("base64,")[1],
          imageType: "jpeg",
        }
      );

      if (requestVisitorSignin.data) {
        if (
          requestVisitorSignin.data.badgePrintingData &&
          stationData.PrinterNameBadge
        ) {
          const printerBaseEndpoint =
            requestVisitorSignin.data.badgePrintingData.printerEndpoint;
          const printerImageBuffer =
            requestVisitorSignin.data.badgePrintingData.badgeImage;

          const uint8Array = new Uint8Array(printerImageBuffer.data);

          const blob = new Blob([uint8Array], { type: "image/png" });

          const file = new File([blob], "image.png", { type: "image/png" });

          const formData = new FormData();
          formData.append("myDocument", file);

          await axios.post(`${printerBaseEndpoint}/Printer/Print`, formData, {
            params: {
              printerName: stationData.PrinterNameBadge,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }

        toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error("Something went wrong, please try again later", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      <Transition appear show={noInteractivity} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000001]"
          onClose={() => {
            setNoInteractivity(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-9 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-bold leading-7 text-gray-900 text-center"
                  >
                    {t("interactivityModalTitle")}
                  </Dialog.Title>
                  <p className="text-lg text-gray-900 text-center mt-4">
                    {t("interactivityModalDetails", {
                      noInteractivityTime,
                    })}
                  </p>
                  <div className="flex justify-center mt-4">
                    <button
                      className="bg-primary px-24 py-3 text-white rounded-lg text-2xl hover:bg-green-200 mt-8"
                      onClick={() => {
                        setNoInteractivity(false);
                        setNoInteractivityTime(0);
                        clearInterval(noInteractivityTimer);
                      }}
                    >
                      {t("interactivityModalButton")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {currentPage === 0 ? (
        <InputForms
          buttonId={parseInt(buttonId)}
          setCurrentPage={setCurrentPage}
          setAnswers={setAnswers}
          answers={answers}
        />
      ) : currentPage === 1 ? (
        <DocumentSign
          buttonId={parseInt(buttonId)}
          setCurrentPage={setCurrentPage}
          setSignatureImageData={setSignatureImageData}
        />
      ) : currentPage === 2 ? (
        <SelfieTake
          buttonId={parseInt(buttonId)}
          setCurrentPage={setCurrentPage}
          selfiePictureImageData={selfiePictureImageData}
          setSelfiePictureImageData={setSelfiePictureImageData}
        />
      ) : currentPage === 3 ? (
        <FinalMessage
          buttonId={parseInt(buttonId)}
          answers={answers}
          handleAddVisitorSignIn={handleAddVisitorSignIn}
        />
      ) : null}
    </>
  );
}
