import axios from "../config/axios";

export const sendUserQuestion = async (question: string, avatarId: number) => {
  try {
    const response = await axios.post("/avatar/question", {
      question,
      avatarId,
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
