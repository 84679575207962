import axios from "axios";
import { getDocumentBufferData } from "./station";

export const sendDocumentToPrinter = async (
  baseUrl: string,
  documentFetchPath: string,
  printerName: string
) => {
  const { data } = await getDocumentBufferData(documentFetchPath);

  const bufferData = new Uint8Array(data.data);

  const blobDocument = new Blob([bufferData], {
    type: "application/pdf",
  });

  const file = new File([blobDocument], "print.pdf", {
    type: "application/pdf",
  });

  const formData = new FormData();
  formData.append("myDocument", file);

  await axios.post(`${baseUrl}/Printer/Print`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      printerName: printerName,
    },
  });

  return true;
};
