import React, { FC, useState } from "react";

interface Props {
  labelText: string;
  options: string[];
  value: string;
  selectedId: number;
  onChange: (value: string) => void;
  extraClasses?: string;
}

const SearchBoxVisitor: FC<Props> = ({
  labelText,
  options,
  value,
  selectedId,
  onChange,
  extraClasses = "",
}) => {
  return (
    <div className={`relative w-full ${extraClasses}`}>
      <label className="text-black">{labelText}</label>
      <input
        type="text"
        className={`w-full border-solid border-lighter-gray border-2 rounded-md py-1 px-2 focus:outline-none mt-1`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        title="Search"
      />

      {value.length > 2 && selectedId === -1 && (
        <div className="absolute bg-white z-40 w-full h-24 overflow-y-scroll mt-1 rounded-b-md border-b border-l border-r border-solid border-lighter-gray border-2 py-1 px-2 pr-2">
          {options
            .filter((option) =>
              option.toLowerCase().includes(value.toLowerCase())
            )
            .map((option, index) => (
              <div
                key={index}
                className={
                  "flex items-center justify-start px-2 py-1 cursor-pointer text-black"
                }
                onClick={() => {
                  onChange(option);
                }}
              >
                <span>{option}</span>
              </div>
            ))}
          {options.filter((option) =>
            option.toLowerCase().includes(value.toLowerCase())
          ).length === 0 && (
            <div className="flex items-center justify-center px-2 py-1 cursor-pointer text-black">
              <span>No results found</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBoxVisitor;
