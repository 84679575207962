import React from "react";

interface DialPadProps {
  onDigitPress: (digit: string) => void;
}

const DialPad = ({ onDigitPress }: DialPadProps) => {
  const digits = [
    { num: "1", letters: "" },
    { num: "2", letters: "ABC" },
    { num: "3", letters: "DEF" },
    { num: "4", letters: "GHI" },
    { num: "5", letters: "JKL" },
    { num: "6", letters: "MNO" },
    { num: "7", letters: "PQRS" },
    { num: "8", letters: "TUV" },
    { num: "9", letters: "WXYZ" },
    { num: "*", letters: "" },
    { num: "0", letters: "+" },
    { num: "#", letters: "" },
  ];

  return (
    <div className="grid grid-cols-3 gap-4 max-w-md mx-auto px-4 py-2">
      {digits.map(({ num, letters }) => (
        <button
          key={num}
          className="flex flex-col items-center justify-center font-semibold rounded-full bg-gray-200 shadow-md hover:bg-gray-300 px-4 py-2 lg:px-6 lg:py-4 xl:px-8 xl:py-6 2xl:px-8 2xl:py-6"
          onClick={() => onDigitPress(num)}
        >
          <span className="text-2xl">{num}</span>
          <span className="text-xs">{letters}</span>
        </button>
      ))}
    </div>
  );
};

export default DialPad;
