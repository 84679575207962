import axios from "../config/axios";

export const getServerTime = async () => {
  const { data } = await axios.get("/app/time");
  return data.time;
};

export const getFirebaseToken = async () => {
  const { data } = await axios.get("/app/firebaseToken");
  return data.data;
};
