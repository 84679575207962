import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StationDataState {
  stationData: any;
  serverTime: any;
  departmentPresences: any[];
  onlineDepartments: any[];
  onlinePeople: any[];
  isOnline: boolean;
}

const initialState: StationDataState = {
  stationData: null,
  serverTime: null,
  departmentPresences: [],
  onlineDepartments: [],
  onlinePeople: [],
  isOnline: false,
};

export const stationDataSlice = createSlice({
  name: "stationDataSlice",
  initialState,
  reducers: {
    setStationData: (state, action: PayloadAction<StationDataState>) => {
      state.stationData = action.payload;
    },
    setServerTime: (state, action: PayloadAction<StationDataState>) => {
      state.serverTime = action.payload;
    },
    setDepartmentPresences: (state, action: PayloadAction<any[]>) => {
      state.departmentPresences = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setOnlineDepartmentsRedux: (state, action: PayloadAction<any[]>) => {
      state.onlineDepartments = action.payload;
    },
    setOnlinePeople: (state, action: PayloadAction<any[]>) => {
      state.onlinePeople = action.payload;
    },
  },
});

export const {
  setStationData,
  setServerTime,
  setDepartmentPresences,
  setIsOnline,
  setOnlineDepartmentsRedux,
  setOnlinePeople,
} = stationDataSlice.actions;

export default stationDataSlice.reducer;
