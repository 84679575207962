import React, { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QRCodeComponent from "react-qr-code";
import { useQuery } from "../hooks";

export default function QrCode() {
  const navigate = useNavigate();
  const { buttonId } = useParams();
  const { t } = useTranslation();
  const query = useQuery();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");

  useEffect(() => {
    if (buttonId && stationData) {
      const isMultipleAttachments =
        query.get("isMultipleAttachments") === "true";

      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === parseInt(buttonId)
      );

      let url = "";

      if (!selectedButton) {
        navigate("/options");
      }

      if (isMultipleAttachments) {
        const selectedAttachment = selectedButton.Attachment.find(
          (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
        );

        if (selectedAttachment) {
          url = selectedAttachment.Url;
        }
      } else {
        url = selectedButton.Attachment.Url;
      }

      if (url) {
        setQrCodeUrl(url);
      }
    }
  }, [buttonId, stationData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/options");
    }, 60000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="w-full h-[100vh]">
      <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            navigate("/options");
          }}
          className="cursor-pointer relative z-50"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="relative h-full w-full flex flex-col justify-center items-center px-5 py-2 z-40">
        <div className="w-full flex items-center gap-5 relative">
          <h1 className="text-3xl md:text-7xl font-bold text-center mb-7 w-full">
            {t("pleaseScan")}
          </h1>
        </div>

        <div className="w-full flex justify-center items-center mt-5">
          {qrCodeUrl ? (
            <QRCodeComponent value={qrCodeUrl} size={400} />
          ) : (
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          )}
        </div>
      </div>
    </div>
  );
}
