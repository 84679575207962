import React, { useCallback, useState } from "react";
import { Stack } from "@fluentui/react";
import {
  ControlBar,
  EndCallButton,
  VideoGallery,
  VideoStreamOptions,
  usePropsFor,
} from "@azure/communication-react";

const isTabletScreen = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return width <= 1100 && height >= 700;
};

export default function StationVideo({
  handleHangOut,
  showIdOverlay,
  call,
}: any) {
  const videoGalleryProps = usePropsFor(VideoGallery);
  const endCallProps = usePropsFor(EndCallButton);

  const [callEnded, setCallEnded] = useState<boolean>(false);

  const containerStyle = {
    height: isTabletScreen() && !showIdOverlay ? "65vh" : "75vh",
    width: "97vw",
    marginTop: "2rem",
  };

  const onHangup = useCallback(async () => {
    call.off("remoteParticipantsUpdated", () => {});
    try {
      await endCallProps.onHangUp();
    } catch (e) {
      console.log("error", e);
    }
    handleHangOut();
    setCallEnded(true);
  }, [call, endCallProps, handleHangOut, setCallEnded]);

  if (callEnded) {
    return (
      <div className="text-center w-full">
        <h1 className="text-3xl font-bold">Call Ended</h1>
      </div>
    );
  }

  const remoteVideoViewOptions = {
    scalingMode: "Fit",
    isMirrored: false,
  } as VideoStreamOptions;

  return (
    <div className="w-full flex flex-col items-center justify-center mb-3">
      <Stack style={containerStyle}>
        {videoGalleryProps && (
          <>
            <VideoGallery
              layout={showIdOverlay ? "default" : "floatingLocalVideo"}
              localVideoViewOptions={remoteVideoViewOptions}
              remoteVideoViewOptions={remoteVideoViewOptions}
              {...videoGalleryProps}
            />
          </>
        )}
      </Stack>
      <div>
        <ControlBar layout="floatingBottom">
          {endCallProps && (
            <EndCallButton {...endCallProps} onHangUp={onHangup} />
          )}
        </ControlBar>
      </div>
    </div>
  );
}
