import React, { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MultipleVisitorSignIns() {
  const navigate = useNavigate();
  const { buttonId } = useParams();
  const { t } = useTranslation();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [attachments, setAttachments] = useState<any[]>([]);

  const [selectedButton, setSelectedButton] = useState<any>(null);

  useEffect(() => {
    if (buttonId && stationData) {
      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === parseInt(buttonId)
      );

      if (selectedButton) {
        setAttachments(selectedButton.Attachment);
        setSelectedButton(selectedButton);
      }
    }
  }, [buttonId, stationData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/options");
    }, 60000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="w-full h-[100vh]">
      <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            navigate("/options");
          }}
          className="cursor-pointer relative z-50"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="relative h-full w-full flex flex-col justify-center items-center px-5 py-2 z-40">
        <div className="container mx-auto w-full lg:w-[95%] mb-24">
          <div
            className={`grid gap-5 lg:gap-7 mt-12 ${
              attachments.length === 1
                ? "grid-cols-1"
                : "portrait:grid-cols-1 landscape:grid-cols-2"
            }`}
          >
            {attachments.map((attachment, index) => {
              if (selectedButton.Enable) {
                return (
                  <div
                    className="w-full flex justify-center items-center"
                    key={index}
                  >
                    <button
                      key={index}
                      className={`flex items-center justify-center rounded-full text-xl md:text-3xl font-bold text-white border-2 hover:saturate-150 hover:brightness-125 py-7 w-[75vw]`}
                      onClick={() => {
                        navigate(
                          `/visitorsignin/${selectedButton.id}?isMultipleAttachments=true&attachmentId=${attachment.id}`
                        );
                      }}
                      style={{
                        backgroundColor: selectedButton.Color
                          ? `${selectedButton.Color}`
                          : "#99e999",
                        borderColor: selectedButton.Color
                          ? `${selectedButton.Color}`
                          : "#99e999",
                        color: selectedButton.TextColor
                          ? `${selectedButton.TextColor}`
                          : "#ffffff",
                      }}
                    >
                      {attachment.Name ? attachment.Name : t("calltype")}
                    </button>
                  </div>
                );
              }

              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
