import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "../../../hooks";

interface InputFormsProps {
  buttonId: number;
  setCurrentPage: (page: number) => void;
  setAnswers: (answer: string[]) => void;
  answers: string[];
}

const sortDepartments = (rawArray: any) => {
  const copyArray = [...rawArray];
  return copyArray.sort((a: any, b: any) => {
    const nameA = a.Name.toUpperCase();
    const nameB = b.Name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

export default function InputForms({
  buttonId,
  setCurrentPage,
  setAnswers,
  answers,
}: InputFormsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = useQuery();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    if (buttonId && stationData) {
      const isMultipleAttachments =
        query.get("isMultipleAttachments") === "true";

      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === buttonId
      );

      if (!selectedButton) {
        navigate("/options");
      }

      let fieldsJson = "";
      if (isMultipleAttachments) {
        const selectedAttachment = selectedButton.Attachment.find(
          (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
        );

        if (selectedAttachment) {
          fieldsJson = selectedAttachment.FieldsJson;
        }
      } else {
        fieldsJson = selectedButton.Attachment.FieldsJson;
      }

      if (fieldsJson) {
        setInputFields(JSON.parse(fieldsJson));

        if (answers.length === 0) {
          const tempAnswers: string[] = [];
          JSON.parse(fieldsJson).forEach(() => {
            tempAnswers.push("");
          });
          setAnswers(tempAnswers);
        }
      } else {
        navigate("/options");
      }
    } else {
      navigate("/options");
    }
  }, [answers.length, buttonId, navigate, setAnswers, stationData]);

  const handleNextPage = async () => {
    let isAllFilled = true;

    inputFields.forEach((field: any, index: number) => {
      if (
        field.Type === "host" &&
        String(answers[index]).trim().length === 0 &&
        stationData.departments.length > 0
      ) {
        const tempAnswers = [...answers];
        tempAnswers[index] = stationData.departments[0].id;
        setAnswers(tempAnswers);

        return;
      }

      if (
        field.Type === "email" &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(answers[index])
      ) {
        isAllFilled = false;
        return;
      }

      if (field.Required && String(answers[index]).trim().length === 0) {
        isAllFilled = false;
      }
    });

    if (!isAllFilled) {
      toast.error("Please fill out all mandatory fields", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    setCurrentPage(1);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center min-h-[90vh] pt-16">
      <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            navigate("/options");
          }}
          className="cursor-pointer relative z-50"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
      </div>

      <div className="relative h-full w-full flex flex-col justify-center items-center px-5 mt-10 gap-8 z-2">
        <p className="text-2xl font-bold">{t("visitorSignInTitle")}</p>
        {inputFields
          .filter(
            (field: any) =>
              field.Type !== "host" ||
              (field.Type === "host" && !field.IsHostSet)
          )
          .map((field: any, index) => (
            <div
              className="w-full md:w-1/2 flex flex-col justify-start items-start border-2 border-gray-200 p-4 rounded-xl"
              key={index}
            >
              <label className="text-xl font-semibold text-black">
                {field.Name}
              </label>
              {field.Type === "host" ? (
                <div className="relative w-full mt-2">
                  <select
                    className="border-b-2 border-primary appearance-none h-10 rounded-lg w-full px-3 pr-8 py-2 text-light-gray focus:outline-none cursor-pointer"
                    title="Select Host"
                    value={answers[index]}
                    onChange={(e) => {
                      const tempAnswers = [...answers];
                      tempAnswers[index] = e.target.value;
                      setAnswers(tempAnswers);
                    }}
                  >
                    {sortDepartments(stationData.departments)
                      .filter((department) => {
                        if (field.HiddenDepartments) {
                          return !field.HiddenDepartments.split("|").includes(
                            `${department.id}`
                          );
                        } else {
                          return true;
                        }
                      })
                      .map((department: any, index: number) => (
                        <option value={department.id} key={index}>
                          {department.Name}
                        </option>
                      ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg
                      className="w-4 h-4 fill-current text-light-gray"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="w-full flex items-center relative">
                  <input
                    type={field.Type ?? "text"}
                    placeholder={field.Required ? "Required" : "Optional"}
                    className="w-full mt-2 border-b-2 border-primary rounded-lg py-2 text-xl bg-transparent text-light-gray focus:outline-none"
                    value={answers[index]}
                    onChange={(e) => {
                      const tempAnswers = [...answers];
                      tempAnswers[index] = e.target.value;
                      setAnswers(tempAnswers);
                    }}
                    onFocus={(e) => e.target.click()}
                  />
                  {answers[index].length > 0 &&
                    (field.Type === "email"
                      ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(answers[index])
                      : true) && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-primary absolute right-0 top-0 mr-2 mt-2 w-6 h-6"
                      />
                    )}
                </div>
              )}
            </div>
          ))}

        <button
          className="bg-primary px-20 py-3 text-white rounded-lg text-2xl hover:bg-green-200"
          onClick={handleNextPage}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
}
