import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setCurrentLanguage } from "../redux/reducers/languageReducer";
import EarthIcon from "../assets/images/earthIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function LanguageSelector() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const currentLanguage = useAppSelector(
    (state) => state.language.currentLanguage
  );
  const stationData = useAppSelector((state) => state.data.stationData);

  return (
    <div className="absolute top-3 right-3 z-[1000001]">
      <div className="flex items-center gap-3">
        <FontAwesomeIcon
          icon={faRotateRight}
          className="w-8 h-8 cursor-pointer mr-3 text-light-gray"
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        />

        {stationData &&
        (stationData.ShowLanguageSelector === true ||
          stationData.ShowLanguageSelector === null) ? (
          <>
            <div className="relative">
              <select
                className="appearance-none w-24 md:w-32 h-10 border-light-gray border-2 rounded-full px-4 py-2 text-light-gray focus:outline-none cursor-pointer"
                value={currentLanguage ?? "en"}
                onChange={(e) => {
                  dispatch(setCurrentLanguage(e.target.value));
                  i18n.changeLanguage(e.target.value);
                }}
              >
                <option value="en">English</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="es">Español</option>
                <option value="it">Italiano</option>
                <option value="no">Norsk</option>
                <option value="pl">Polski</option>
                <option value="ru">Русский</option>
                <option value="uk">Українська</option>
                <option value="ar">العربية</option>
                <option value="fi">Suomi</option>
                <option value="hu">Magyar</option>
                <option value="ja">日本語</option>
                <option value="ko">한국어</option>
                <option value="ro">Română</option>
                <option value="tr">Türkçe</option>
                <option value="sv">Svenska</option>
                <option value="fil">Filipino</option>
                <option value="zh">中文</option>
                <option value="nl">Nederlands</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 fill-current text-light-gray"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  />
                </svg>
              </div>
            </div>

            <img
              src={EarthIcon}
              alt="earth icon"
              className="hidden md:block w-10 h-10"
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
