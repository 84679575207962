import React, { Fragment, useEffect, useState } from "react";
import InputWithLabel from "../components/InputWithLabel";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getStationByDwelling,
  sendEmailNotification,
  sendFCMNotification,
  sendSMS,
  visitorCheckOut,
} from "../api";
import { Dialog, Transition } from "@headlessui/react";
import { setCallData } from "../redux/reducers/callReducer";
import { setDwellingData } from "../redux/reducers/dwellingReducer";
import QRCode from "react-qr-code";
import LoadingScreen from "../components/LoadingScreen";
import SearchBoxVisitor from "../components/SearchBoxVisitor";
import { toast } from "react-toastify";

export default function Options() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const stationData = useAppSelector((state) => state.data.stationData);
  const currentLanguage = useAppSelector(
    (state) => state.language.currentLanguage
  );
  const serverDepartmentPresence = useAppSelector(
    (state) => state.data.departmentPresences
  );

  const [search, setSearch] = useState<string>("");
  const [callTypeDisable, setCallTypeDisable] = useState<string | null>(null);
  const [searchVisitor, setSearchVisitor] = useState<string>("");

  const [selectedSignedInVisitor, setSelectedSignedInVisitor] =
    useState<number>(-1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSentEmail, setIsSentEmail] = useState<boolean>(false);
  const [isSignOut, setIsSignOut] = useState<boolean>(false);

  const [callTypeDisableTimeout, setCallTypeDisableTimeout] =
    useState<any>(null);
  const [departmentPresences, setDepartmentPresences] = useState<any[]>([]);
  const [allSignedInVisitors, setAllSignedInVisitors] = useState<any[]>([]);

  useEffect(() => {
    if (
      stationData &&
      stationData.backgroundImage &&
      stationData.backgroundImage.Url
    ) {
      document.body.style.backgroundImage = `url(${stationData.backgroundImage.Url})`;
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundSize = "cover";
      document.body.style.minHeight = "100vh";
    }

    return () => {
      document.body.style.backgroundImage = "none";
    };
  }, [stationData]);

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      i18n.changeLanguage(currentLanguage ?? "en");
    }
  }, [currentLanguage, i18n]);

  useEffect(() => {
    if (i18n.language === "fr") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeFR.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "ar") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeAR.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "de") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeDE.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "es") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeES.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "fi") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeFI.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "hu") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeHU.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "it") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeIT.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "ja") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeJA.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "ko") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeKO.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "ro") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeRO.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "ru") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeRU.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "sv") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeSV.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "tr") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeTR.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "uk") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeUK.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "pl") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypePL.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "nb") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeNO.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else if (i18n.language === "fil") {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeFIL.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    } else {
      if (search.length > 0) {
        const filteredButtons = departmentPresences.filter((button) =>
          button.CallTypeEN.toLowerCase().includes(search.toLowerCase())
        );
        setDepartmentPresences(filteredButtons);
      } else {
        setDepartmentPresences(serverDepartmentPresence);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (serverDepartmentPresence) {
      setDepartmentPresences(serverDepartmentPresence);
    }
  }, [serverDepartmentPresence]);

  const handleButtonClick = async (departmentPresence: any) => {
    if (
      departmentPresence.isOnline ||
      (departmentPresence.FallBackCall &&
        departmentPresence.FallBackCall.length > 0)
    ) {
      const selectedDepartments = departmentPresence.SelectedDepartments;
      const selectedEmployees = departmentPresence.SelectedEmployees;
      const selectedNotification = departmentPresence.SelectedNotification;

      let callTypeName = departmentPresence[
        "CallType" + i18n.language.toLocaleUpperCase()
      ]
        ? departmentPresence["CallType" + i18n.language.toLocaleUpperCase()]
        : null;

      if (callTypeName === null) {
        callTypeName = t("calltype");
      }

      if (departmentPresence.IsMultipleAttachments) {
        navigate(`/multipleattachments/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsMultipleVisitorSigns) {
        navigate(`/multiplevisitorsignins/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsMultipleSelectedEmployees) {
        navigate(`/multipleemployees/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsQR) {
        navigate(`/qrcode/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsPDF) {
        navigate(`/document/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsAvatar) {
        navigate(`/avatar/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsNotification) {
        setIsLoading(true);

        const notificationEmail = selectedNotification.includes("|")
          ? selectedNotification.split("|")[0]
          : selectedNotification;

        const notificationSms = selectedNotification.includes("|")
          ? selectedNotification.split("|")[1]
          : "";

        if (notificationEmail) {
          await sendEmailNotification(
            notificationEmail,
            stationData.StationName,
            callTypeName,
            stationData.OwnerId
          );
        }

        if (notificationSms) {
          await sendSMS(
            [notificationSms],
            `Station button pressed: ${stationData.StationName} - ${callTypeName}`
          );
        }

        await sendEmailNotification(
          selectedNotification,
          stationData.StationName,
          callTypeName,
          stationData.OwnerId
        );

        setIsLoading(false);

        setIsSentEmail(true);

        setTimeout(() => {
          setIsSentEmail(false);
        }, 5000);

        return;
      }

      if (departmentPresence.IsVisitorSignIn) {
        navigate(`/visitorsignin/${departmentPresence.id}`);
        return;
      }

      if (departmentPresence.IsVisitorSignOut) {
        setAllSignedInVisitors(departmentPresence.Attachment);
        setIsSignOut(true);
        return;
      }

      if (departmentPresence.isOnline) {
        if (
          selectedDepartments &&
          selectedDepartments.length > 1 &&
          selectedDepartments.split("|").length > 1
        ) {
          navigate(`/depoption/${departmentPresence.id}`);
        } else {
          let callData: any;

          callData = {
            callType: departmentPresence.id,
            callTypeName: callTypeName,
            selectedDepartments: selectedDepartments,
            selectedEmployees: selectedEmployees,
            fallBackCall: departmentPresence.FallBackCall,
            microsoftCallerIds: departmentPresence.MicrosoftCallerIds,
            regularUserIds: departmentPresence.RegularUserIds,
            callForwardTime: departmentPresence.CallForwardTime
              ? departmentPresence.CallForwardTime
              : stationData.CallForwardTime,
          };
          if (
            departmentPresence.fcmTokens &&
            departmentPresence.fcmTokens.length > 0
          ) {
            sendFCMNotification(
              departmentPresence.fcmTokens,
              `${t("fcmBody")} ${stationData.StationName} - ${
                callData.callTypeName
              }`,
              `${t("fcmTitle")}`
            );
          }
          if (
            departmentPresence.smsArr &&
            departmentPresence.smsArr.length > 0
          ) {
            sendSMS(
              departmentPresence.smsArr,
              `${t("fcmBody")} ${stationData.StationName} - ${
                callData.callTypeName
              }`
            );
          }

          const dwelling = await getStationByDwelling(stationData.DwellingId);
          if (dwelling.status === 1) {
            dispatch(setCallData(callData));
            dispatch(setDwellingData(dwelling.data));
            navigate("/call");
          }
        }
      } else {
        if (
          (selectedDepartments &&
            selectedDepartments.length > 1 &&
            selectedDepartments.split("|").length > 1) ||
          departmentPresence.FallBackCall === "12345678910111213141516"
        ) {
          navigate(`/depoption/${departmentPresence.id}`);
        } else {
          let callData: any;
          if (!!departmentPresence.FallBackCall) {
            callData = {
              fallBackCall: departmentPresence.FallBackCall,
            };

            dispatch(setCallData(callData));
            navigate("/call-fallback");
          } else {
            callData = {
              callType: departmentPresence.id,
              callTypeName: callTypeName,
              selectedDepartments: selectedDepartments,
              selectedEmployees: selectedEmployees,
              fallBackCall: departmentPresence.FallBackCall,
              microsoftCallerIds: departmentPresence.MicrosoftCallerIds,
              regularUserIds: departmentPresence.RegularUserIds,
              callForwardTime: departmentPresence.CallForwardTime
                ? departmentPresence.CallForwardTime
                : stationData.CallForwardTime,
            };
            if (
              departmentPresence.fcmTokens &&
              departmentPresence.fcmTokens.length > 0
            ) {
              sendFCMNotification(
                departmentPresence.fcmTokens,
                `${t("fcmBody")} ${stationData.StationName} - ${
                  callData.callTypeName
                }`,
                `${t("fcmTitle")}`
              );
            }
            if (
              departmentPresence.smsArr &&
              departmentPresence.smsArr.length > 0
            ) {
              sendSMS(
                departmentPresence.smsArr,
                `${t("fcmBody")} ${stationData.StationName} - ${
                  callData.callTypeName
                }`
              );
            }

            const dwelling = await getStationByDwelling(stationData.DwellingId);

            if (dwelling.status === 1) {
              dispatch(setCallData(callData));
              dispatch(setDwellingData(dwelling.data));
              navigate("/call");
            }
          }
        }
      }
    } else {
      setCallTypeDisable(t("departmentAlertDetails"));

      setCallTypeDisableTimeout(
        setTimeout(() => {
          setCallTypeDisable(null);
        }, 5000)
      );
    }
  };

  const handleSignOut = async () => {
    setIsLoading(true);

    await visitorCheckOut(selectedSignedInVisitor);

    setIsLoading(false);

    toast.success(t("visitorSignOutMessage"), {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 2500);
  };
  return (
    <>
      {(isLoading || !stationData) && <LoadingScreen />}

      <Transition appear show={!!callTypeDisable} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setCallTypeDisable(null);

            if (callTypeDisableTimeout) {
              clearTimeout(callTypeDisableTimeout);
            }
            setCallTypeDisableTimeout(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all">
                  <div className="absolute top-0 right-0 mt-4 mr-4">
                    <button
                      type="button"
                      className="bg-white rounded-full flex items-center justify-center text-gray-400 focus:outline-none"
                      onClick={() => {
                        setCallTypeDisable(null);

                        if (callTypeDisableTimeout) {
                          clearTimeout(callTypeDisableTimeout);
                        }
                        setCallTypeDisableTimeout(null);
                      }}
                    >
                      <span className="sr-only">Close</span>

                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900 text-center"
                  >
                    {t("departmentAlertTitle")}
                  </Dialog.Title>
                  <div className="mt-6 w-full flex justify-center">
                    <p className="text-light-gray text-xl text-center">
                      {callTypeDisable}
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isSentEmail} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsSentEmail(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all">
                  <div className="absolute top-0 right-0 mt-4 mr-4">
                    <button
                      type="button"
                      className="bg-white rounded-full flex items-center justify-center text-gray-400 focus:outline-none"
                      onClick={() => {
                        setIsSentEmail(false);
                      }}
                    >
                      <span className="sr-only">Close</span>

                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900 text-center"
                  >
                    {t("emailAlertTitle")}
                  </Dialog.Title>

                  <p className="text-light-gray text-xl text-center mt-2">
                    {t("emailAlertDescription")}
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isSignOut} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsSignOut(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6  text-left align-middle shadow-xl transition-all">
                  <div className="absolute top-0 right-0 mt-4 mr-4">
                    <button
                      type="button"
                      className="bg-white rounded-full flex items-center justify-center text-gray-400 focus:outline-none"
                      onClick={() => {
                        setIsSignOut(false);
                      }}
                    >
                      <span className="sr-only">Close</span>

                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <SearchBoxVisitor
                    labelText="Search visitor"
                    options={allSignedInVisitors.map((visitor) => visitor.Name)}
                    value={searchVisitor}
                    selectedId={selectedSignedInVisitor}
                    onChange={(e) => {
                      setSearchVisitor(e);
                      setSelectedSignedInVisitor(
                        allSignedInVisitors.find(
                          (visitor) => visitor.Name === e
                        )?.id ?? -1
                      );
                    }}
                    extraClasses="mt-12"
                  />

                  <div className="w-full flex justify-center mt-10">
                    <button
                      className={`${selectedSignedInVisitor === -1 ? "bg-light-gray" : "bg-primary"} text-white font-semibold rounded-lg py-2 px-8`}
                      onClick={handleSignOut}
                      disabled={selectedSignedInVisitor === -1}
                    >
                      {t("visitorSignOutButton")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="w-full flex justify-center mt-10">
        {stationData && stationData.logoImage && stationData.logoImage.Url ? (
          <img
            src={stationData.logoImage.Url}
            alt="logo"
            style={{ width: stationData.LogoSize ?? 150 }}
          />
        ) : null}
      </div>

      <div
        className={`w-full ${!stationData || !stationData.StationVerticalAlignment || stationData.StationVerticalAlignment === "center" ? "min-h-[75vh]" : "h-[80vh]"} flex flex-col justify-center pb-5`}
      >
        <div className="h-full w-full flex flex-col justify-center items-center px-5 mt-10">
          {stationData &&
            (stationData[`WelcomeText${i18n.language.toLocaleUpperCase()}`] ===
              null ||
            stationData.WelcomeTextEN ===
              "<h1>How can we help you today?</h1>" ? (
              <h1
                className="text-3xl md:text-6xl font-bold text-black mb-5 text-center"
                style={{
                  color:
                    stationData && stationData.WelcomeTextColor
                      ? stationData.WelcomeTextColor
                      : "#000000",
                }}
              >
                {t("optionsTitle")}
              </h1>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    stationData[
                      `WelcomeText${i18n.language.toLocaleUpperCase()}`
                    ],
                }}
                style={{
                  color:
                    stationData && stationData.WelcomeTextColor
                      ? stationData.WelcomeTextColor
                      : "#000000",
                  width: "70%",
                }}
              />
            ))}

          {stationData && stationData.qrCode && stationData.qrCode.Url ? (
            <div className="w-full flex items-center justify-center my-1 3xl:my-4">
              <QRCode value={stationData.qrCode.Url} size={200} />
            </div>
          ) : null}

          {stationData &&
            stationData.ShowSearchBar &&
            serverDepartmentPresence.length > 5 && (
              <InputWithLabel
                type="text"
                value={search}
                setValue={setSearch}
                placeholder={t("search")}
                extraClasses="mt-7 w-80"
                placeholderSize=""
                textClasses="rounded-full py-3 pb-3 px-5"
                isGreen={false}
                hasSeparatePlaceholder
              />
            )}

          <div
            className={`container mx-auto w-full lg:w-[95%] mb-24 ${
              !stationData ||
              !stationData.StationVerticalAlignment ||
              stationData.StationVerticalAlignment === "center"
                ? ""
                : `h-full flex flex-col ${
                    stationData.StationVerticalAlignment === "top"
                      ? "justify-start"
                      : "justify-end"
                  }`
            }`}
          >
            <div
              className={`grid justify-items-center gap-5 lg:gap-7 mt-12 ${
                departmentPresences.length === 1
                  ? "grid-cols-1"
                  : `${stationData ? (stationData.StationColNumber === 1 ? "landscape:grid-cols-1 portrait:grid-cols-1" : stationData.StationColNumber === 2 ? "landscape:grid-cols-2 portrait:grid-cols-2" : stationData.StationColNumber === 3 ? "landscape:grid-cols-3 portrait:grid-cols-2" : stationData.StationColNumber === 4 ? "landscape:grid-cols-4 portrait:grid-cols-2" : stationData.StationColNumber === 5 ? "landscape:grid-cols-5 portrait:grid-cols-2" : "landscape:grid-cols-6 portrait:grid-cols-2") : "portrait:grid-cols-1 landscape:grid-cols-2"}`
              }`}
            >
              {departmentPresences.map((departmentPresence, index) => {
                const isDisabled =
                  !departmentPresence.isOnline &&
                  (departmentPresence.FallBackCall === null ||
                    departmentPresence.FallBackCall === undefined ||
                    departmentPresence.FallBackCall.trim().length === 0);

                if (departmentPresence.Enable) {
                  if (
                    departmentPresence.backgroundImage &&
                    departmentPresence.backgroundImage.Url
                  ) {
                    return (
                      <div
                        className="relative rounded-full w-full flex justify-center items-end h-[20rem] w-[25rem] shrink-0"
                        key={index}
                      >
                        <img
                          src={departmentPresence.backgroundImage.Url}
                          alt="background"
                          className="w-full h-full object-cover rounded-2xl"
                        />

                        <button
                          onClick={() => handleButtonClick(departmentPresence)}
                          className={`absolute bottom-5 rounded-full text-xl md:text-3xl font-bold text-white ${
                            isDisabled
                              ? "border-2"
                              : "text-white border-2 hover:saturate-150 hover:brightness-125"
                          } py-7 w-3/5`}
                          style={{
                            backgroundColor: isDisabled
                              ? "#d6d6d6"
                              : departmentPresence.Color
                                ? `${departmentPresence.Color}`
                                : "#99e999",
                            borderColor: isDisabled
                              ? "#d6d6d6"
                              : departmentPresence.Color
                                ? `${departmentPresence.Color}`
                                : "#99e999",
                            color: isDisabled
                              ? "#ffffff"
                              : departmentPresence.TextColor
                                ? `${departmentPresence.TextColor}`
                                : "#ffffff",
                          }}
                        >
                          {departmentPresence[
                            "CallType" + i18n.language.toLocaleUpperCase()
                          ]
                            ? i18n.language === "en"
                              ? departmentPresence[
                                  "CallType" + i18n.language.toLocaleUpperCase()
                                ]
                              : departmentPresence[
                                  "CallType" + i18n.language.toLocaleUpperCase()
                                ].split(".")[0]
                            : t("calltype")}
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="w-full flex justify-center items-center"
                        key={index}
                      >
                        <button
                          className={`flex items-center justify-center rounded-full text-xl md:text-3xl font-bold text-white ${
                            isDisabled
                              ? "border-2"
                              : "text-white border-2 hover:saturate-150 hover:brightness-125"
                          } py-7 w-[75vw]`}
                          onClick={() => handleButtonClick(departmentPresence)}
                          style={{
                            backgroundColor: isDisabled
                              ? "#d6d6d6"
                              : departmentPresence.Color
                                ? `${departmentPresence.Color}`
                                : "#99e999",
                            borderColor: isDisabled
                              ? "#d6d6d6"
                              : departmentPresence.Color
                                ? `${departmentPresence.Color}`
                                : "#99e999",
                            color: isDisabled
                              ? "#ffffff"
                              : departmentPresence.TextColor
                                ? `${departmentPresence.TextColor}`
                                : "#ffffff",
                          }}
                        >
                          {departmentPresence[
                            "CallType" + i18n.language.toLocaleUpperCase()
                          ]
                            ? i18n.language === "en"
                              ? departmentPresence[
                                  "CallType" + i18n.language.toLocaleUpperCase()
                                ]
                              : departmentPresence[
                                  "CallType" + i18n.language.toLocaleUpperCase()
                                ].split(".")[0]
                            : t("calltype")}
                        </button>
                      </div>
                    );
                  }
                }

                return null;
              })}
            </div>
          </div>

          {search.length > 0 && departmentPresences.length === 0 && (
            <div className="w-full flex justify-center items-center">
              <p
                className="text-4xl"
                style={{
                  color:
                    stationData && stationData.WelcomeTextColor
                      ? stationData.WelcomeTextColor
                      : "#a2a2a2",
                }}
              >
                {t("result")}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
