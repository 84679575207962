import React, { useEffect } from "react";
import { useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Offline() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const stationData = useAppSelector((state) => state.data.stationData);
  const isOnline = useAppSelector((state) => state.data.isOnline);

  useEffect(() => {
    if (isOnline) {
      navigate("/");
    }
  }, [isOnline, navigate]);

  return (
    <div className="w-full h-[100vh]">
      <div className="fixed top-0 w-full flex justify-center mt-4">
        {stationData && stationData.logoImage && stationData.logoImage.Url ? (
          <img
            src={stationData.logoImage.Url}
            alt="logo"
            style={{ width: stationData.LogoSize ?? 150 }}
          />
        ) : null}
      </div>
      <div className="h-full w-full flex flex-col justify-center items-center px-5">
        <h1 className="text-3xl md:text-6xl font-bold text-center text-black mb-7 w-full lg:w-1/2">
          {t("offlineTitle")}
        </h1>
        <p className="text-lg md:text-xl text-center w-full lg:w-1/2">
          {t("offlineDetails")}
        </p>
      </div>
    </div>
  );
}
