import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { getDocumentBufferData } from "../../../api";
import { Page, Document, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "../../../hooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface DocumentSignProps {
  buttonId: number;
  setCurrentPage: (page: number) => void;
  setSignatureImageData: (imageData: string) => void;
}

export default function DocumentSign({
  buttonId,
  setCurrentPage,
  setSignatureImageData,
}: DocumentSignProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = useQuery();

  const stationData = useAppSelector((state) => state.data.stationData);

  const [canvasWidth, setCanvasWidth] = useState<number>(600);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);

  const signature = useRef(null);
  const container = useRef<HTMLDivElement | null>(null);
  const [documentBufferData, setDocumentBufferData] = useState<any>(null);

  const file = useMemo(
    () => ({ data: documentBufferData }),
    [documentBufferData]
  );

  useEffect(() => {
    if (container.current) {
      setCanvasWidth(container.current.offsetWidth);
    }
  }, [container]);

  useEffect(() => {
    const fetchDocument = async (filePath: string) => {
      const requestData = await getDocumentBufferData(filePath);

      return requestData;
    };

    if (buttonId && stationData) {
      const isMultipleAttachments =
        query.get("isMultipleAttachments") === "true";

      const selectedButton = stationData.stationButton.find(
        (a: any) => a.id === buttonId
      );

      if (!selectedButton) {
        navigate("/options");
      }

      let filePath = "";
      if (isMultipleAttachments) {
        const selectedAttachment = selectedButton.Attachment.find(
          (a: any) => a.id === parseInt(query.get("attachmentId") ?? "0")
        );

        if (selectedAttachment && selectedAttachment.documents.length > 0) {
          filePath = selectedAttachment.documents[0].FilePath;
        } else {
          setCurrentPage(2);
        }
      } else {
        if (
          selectedButton.Attachment.documents &&
          selectedButton.Attachment.documents.length > 0
        ) {
          filePath = selectedButton.Attachment.documents[0].FilePath;
        } else {
          setCurrentPage(2);
        }
      }

      if (filePath) {
        fetchDocument(filePath)
          .then((data) => {
            if (data) {
              setDocumentBufferData(data.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/options");
    }
  }, [buttonId, navigate, setCurrentPage, stationData]);

  const handleNextPage = () => {
    let isAllFilled = true;

    if (signature.current && (signature.current as any).isEmpty()) {
      isAllFilled = false;
    }

    if (!isAllFilled) {
      toast.error("Please sign the document", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    setSignatureImageData(
      (signature.current as any).getTrimmedCanvas().toDataURL("image/png")
    );

    setCurrentPage(2);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center min-h-[90vh] pt-16">
      <div className="absolute top-0 left-0 w-full h-full flex justify-start items-start z-1 mt-24">
        <svg
          width="96"
          height="96"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            setCurrentPage(0);
          }}
          className="cursor-pointer relative z-50"
        >
          <path
            d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
            fill="black"
          />
        </svg>
      </div>

      <div className="relative w-full flex justify-center items-center flex-col mt-10 z-2">
        <div className="relative flex justify-center items-center">
          {documentBufferData ? (
            <Document
              file={file}
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
              }}
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          ) : (
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          )}

          {numPages > 1 && pageNumber > 1 && (
            <button
              className="absolute -left-[12rem] top-1/2 z-[100001] bg-gray-900 text-white px-5 py-2 rounded-md"
              onClick={() => setPageNumber(pageNumber - 1)}
              title="Previous Page"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="w-6 h-6 cursor-pointer"
              />
            </button>
          )}

          {numPages > 1 && pageNumber < numPages && (
            <button
              className="absolute -right-[12rem] top-1/2 z-[100001] bg-gray-900 text-white px-5 py-2 rounded-md"
              onClick={() => setPageNumber(pageNumber + 1)}
              title="Next Page"
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="w-6 h-6 cursor-pointer"
              />
            </button>
          )}
        </div>

        <div
          className="w-1/2 mt-8 relative flex justify-center flex-col items-center"
          ref={container}
        >
          <p className="text-xl text-lighter-gray font-bold text-center">
            {`${t("docsAgreed")} (${t("copyEmail")})`}
          </p>
          <p className="text-xl text-black font-bold text-center absolute -mb-36">
            {t("signature")}
          </p>
          <SignatureCanvas
            ref={signature}
            penColor="black"
            canvasProps={{
              width: canvasWidth,
              height: 150,
              className: "border-2 border-light-gray rounded-lg mt-2 bg-white",
            }}
            backgroundColor="#ffffff"
          />
        </div>
        <button
          className="bg-primary px-24 py-3 text-white rounded-lg text-2xl hover:bg-green-200 mt-3 mb-3"
          onClick={handleNextPage}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
}
