import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./config/i18n";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Activate from "./routes/Activate";
import Error from "./routes/Error";
import Options from "./routes/Options";
import Offline from "./routes/Offline";
import Stars from "./routes/Stars";
import StationWrapper from "./components/StationWrapper";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Call from "./routes/Call";
import PhoneCall from "./routes/PhoneCallTwilio";
import DepOption from "./routes/DepOption";
import * as Sentry from "@sentry/react";
import Footer from "./components/Footer";
import LanguageSelector from "./components/LanguageSelector";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import QrCode from "./routes/QrCode";
import Document from "./routes/Document";
import VisitorSignIn from "./routes/VisitorSignIn/VisitorSignIn";
import Avatar from "./routes/Avatar";
import Maintenance from "./routes/Maintenance";
import MultipleAttachments from "./routes/MultipleAttachments";
import MultipleVisitorSignIns from "./routes/MultipleVisitorSignIns";
import MultipleEmployees from "./routes/MultipleEmployees";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

ReactGA.initialize("G-EBSRQ7VC07");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AZURE_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

reportWebVitals(SendAnalytics);
appInsights.loadAppInsights();

const router = createBrowserRouter([
  {
    path: "/multipleemployees/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <MultipleEmployees />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/multiplevisitorsignins/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <MultipleVisitorSignIns />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/multipleattachments/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <MultipleAttachments />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/visitorsignin/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <VisitorSignIn />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/document/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Document />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/qrcode/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <QrCode />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/avatar/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Avatar />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/depoption/:buttonId",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <DepOption />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/call-fallback",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <PhoneCall />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/call",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Call />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/evaluation",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Stars />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/offline",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Offline />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/options",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Options />
        <Footer />
      </StationWrapper>
    ),
  },
  {
    path: "/",
    element: (
      <StationWrapper>
        <LanguageSelector />
        <Activate />
        <Footer />
      </StationWrapper>
    ),
    errorElement: <Error />,
  },
]);

root.render(
  <React.StrictMode>
    {process.env.REACT_APP_IS_MAINTENANCE === "true" ? (
      <Maintenance />
    ) : (
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
      </Provider>
    )}
  </React.StrictMode>
);
