export const avatarCoordinates = [
  {
    index: 23,
    score: 0.00004770448140334338,
    categoryName: "jawForward",
    displayName: "",
  },
  {
    index: 24,
    score: 0.0024359794333577156,
    categoryName: "jawLeft",
    displayName: "",
  },
  {
    index: 25,
    score: 0.00004770448140334338,
    categoryName: "jawOpen",
    displayName: "",
  },
  {
    index: 26,
    score: 0.0008091731579042971,
    categoryName: "jawRight",
    displayName: "",
  },
  {
    index: 27,
    score: 0.003406928852200508,
    categoryName: "mouthClose",
    displayName: "",
  },
  {
    index: 28,
    score: 0.0017561826389282942,
    categoryName: "mouthDimpleLeft",
    displayName: "",
  },
  {
    index: 29,
    score: 0.004075265023857355,
    categoryName: "mouthDimpleRight",
    displayName: "",
  },
  {
    index: 30,
    score: 0.000187078068847768,
    categoryName: "mouthFrownLeft",
    displayName: "",
  },
  {
    index: 31,
    score: 0.00013820860476698726,
    categoryName: "mouthFrownRight",
    displayName: "",
  },
  {
    index: 32,
    score: 0.01694287732243538,
    categoryName: "mouthFunnel",
    displayName: "",
  },
  {
    index: 33,
    score: 0.0003216993354726583,
    categoryName: "mouthLeft",
    displayName: "",
  },
  {
    index: 34,
    score: 0.0040372926741838455,
    categoryName: "mouthLowerDownLeft",
    displayName: "",
  },
  {
    index: 35,
    score: 0.003765778848901391,
    categoryName: "mouthLowerDownRight",
    displayName: "",
  },
  {
    index: 36,
    score: 0.008966824039816856,
    categoryName: "mouthPressLeft",
    displayName: "",
  },
  {
    index: 37,
    score: 0.00455024978145957,
    categoryName: "mouthPressRight",
    displayName: "",
  },
  {
    index: 38,
    score: 0.11292340606451035,
    categoryName: "mouthPucker",
    displayName: "",
  },
  {
    index: 39,
    score: 0.010613995604217052,
    categoryName: "mouthRight",
    displayName: "",
  },
  {
    index: 40,
    score: 0.023443344980478287,
    categoryName: "mouthRollLower",
    displayName: "",
  },
  {
    index: 41,
    score: 0.01363126840442419,
    categoryName: "mouthRollUpper",
    displayName: "",
  },
  {
    index: 42,
    score: 0.030539097264409065,
    categoryName: "mouthShrugLower",
    displayName: "",
  },
  {
    index: 43,
    score: 0.18937447667121887,
    categoryName: "mouthShrugUpper",
    displayName: "",
  },
  {
    index: 44,
    score: 0.00007681291754124686,
    categoryName: "mouthSmileLeft",
    displayName: "",
  },
  {
    index: 45,
    score: 0.00004737923518405296,
    categoryName: "mouthSmileRight",
    displayName: "",
  },
  {
    index: 46,
    score: 0.0002694524300750345,
    categoryName: "mouthStretchLeft",
    displayName: "",
  },
  {
    index: 47,
    score: 0.00014711888798046857,
    categoryName: "mouthStretchRight",
    displayName: "",
  },
  {
    index: 48,
    score: 0.015663007274270058,
    categoryName: "mouthUpperUpLeft",
    displayName: "",
  },
  {
    index: 49,
    score: 0.018849926069378853,
    categoryName: "mouthUpperUpRight",
    displayName: "",
  },
];
